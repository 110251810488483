<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 25 25"
  >
    <defs>
      <linearGradient
        :id="id"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#0DDB77"
        />
        <stop
          offset="100%"
          stop-color="#0AB130"
        />
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <circle
        :fill="`url(#${id})`"
        cx="10"
        cy="10"
        r="10"
      />
      <path
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
        d="M5.415 9.91l2.795 4.144L5.415 9.91zm8.72-4.202L8.36 13.87l5.776-8.162z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OnlineIcon',
  data() {
    return {
      id: 1,
    };
  },
  mounted() {
    this.id = Math.random();
  },
};
</script>

<style scoped>
path {
  stroke-width: 2px;
}
</style>
