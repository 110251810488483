<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="90"
    viewBox="0 0 90 90"
  >
    <g
      fill="#FFF"
      fill-rule="nonzero"
    >
      <path d="M45 31.49l.022.007 19.034-10.969L45 9.051 25.944 20.535l19.034
      10.97.022-.015zm-.007-20.48l.007.008.007-.007 15.746 9.495L45
      29.581l-15.753-9.075 15.746-9.495zM23.417 22.249l-17.96 10.83 18.055
      10.934L42.72 33.276 23.417 22.249zM8.695 33.087l14.751-8.893 15.877
      9.075-15.775 8.805-14.853-8.987zM24.898 69.603l18.788
      10.78V58.815l-18.788-10.78zM5.007 58.082L22.54 68.448V46.88L5.007
      36.1zM66.583 22.249L47.279 33.276l19.21 10.737 18.06-10.926L66.584
      22.25zm-.131 19.825l-15.768-8.813 15.877-9.074 14.751 8.893-14.86
      8.994zM46.314 80.383l18.788-10.78V48.034l-18.788 10.78zM67.461
      46.88v21.568l17.532-10.366V36.1zM45 34.735l-19.1 10.89 19.1 11.1 19.1-11.1z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CodesysIcon',
};
</script>
