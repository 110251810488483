/* eslint-disable array-callback-return */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import DeployDetails from '@/services/api/deploy-details.api-service';
import DeployDetailsModel from '@/model/deploy-details/deploy-details.model';

export default {
  state: {
    campaign: {
      timeOfOperationStart: '',
      timeOfOperationFinish: '',
      campaignOptions: {
        progress: 0,
      },
      status: '',
    },
    tasks: [],
    deployDetailsCount: 0,
    content: {},
    campaignId: {},
    resetTask: {},
    bulkOperation: {},
  },
  mutations: {
    SET_DEPLOY_DETAILS_LIST(state, list) {
      state.tasks = list.data.map((deployed) => new DeployDetailsModel({ ...deployed }));
    },
    SET_CAMPAIGN(state, content) {
      state.campaign = content;
      state.campaign.timeOfOperationStart = state.campaign.timeOfOperationStart ? new Date(state.campaign.timeOfOperationStart).toLocaleString('en-GB') : '';
      state.campaign.timeOfOperationFinish = state.campaign.timeOfOperationFinish ? new Date(state.campaign.timeOfOperationFinish).toLocaleString('en-GB') : '';
    },
    SET_DEPLOY_DETAILS_COUNT(state, count) {
      state.deployDetailsCount = count;
    },
    SET_CONTENT_LOG(state, list) {
      state.content = list;
    },
    SET_RESET_TASK(state, task) {
      state.resetTask = task;
    },
    SET_BULK_OPERATION(state, content) {
      state.bulkOperation = content;
    },
    UPDATE_CAMPAIGNS(state, payload) {
      state.campaign.campaignOptions.progress = payload.progress;
      state.campaign.status = payload.status.charAt(0) + payload.status.slice(1).toLowerCase().replace('_', ' ') || '';
      state.campaign.timeOfOperationStart = new Date(payload.timeOfOperationStart).toLocaleString('en-GB');
      state.campaign.timeOfOperationFinish = state.campaign.timeOfOperationFinish ? new Date(payload.timeOfOperationFinish).toLocaleString('en-GB') : '';
    },
    UPDATE_TASK(state, payload) {
      state.tasks.map((detail) => {
        if (detail.id === payload.id) {
          detail.progress = payload.progress.toFixed(2);
          detail.status = payload.status.charAt(0) + payload.status.slice(1).toLowerCase().replace('_', ' ') || '';
          detail.timeOfTaskStart = new Date(payload.timeOfTaskStart).toLocaleString('en-GB');
          // eslint-disable-next-line no-nested-ternary
          detail.finished = payload.status === 'SUCCESS'
            ? (payload.taskFinishedTimestamp ? new Date(payload.taskFinishedTimestamp).toLocaleString('en-GB') : '') : ((payload.status === 'Error' || payload.status === 'FAILED') ? new Date(payload.errorFeedback.timeOfFail).toLocaleString('en-GB') : '');
          detail.retryCounter = `${payload.triedCounter}/${payload.retryCounter}`;
          detail.errorFeedback = payload.errorFeedback;
        }
      });
    },
    FINISHED_CAMPAIGN(state, payload) {
      if (state.bulkOperation._id === payload.id) {
        state.campaign.campaignOptions.progress = payload.progress;
        state.campaign.status = payload.status.charAt(0) + payload.status.slice(1).toLowerCase().replace('_', ' ') || '';
        state.campaign.timeOfOperationFinish = new Date(payload.timeOfOperationFinish).toLocaleString('en-GB');
      }
    },
  },
  getters: {
    list: (state) => state.tasks,
    count: (state) => state.deployDetailsCount,
    content: (state) => state.content,
    campaign: (state) => state.campaign,
    bulkOperation: (state) => state.bulkOperation,
  },

  actions: {
    async fetch({ commit }, data) {
      let params = {};
      if (data.id) {
        if (data.search === '') {
          params = {
            filterBy: {
              isFinished: data.selectedSuccessful,
              isFailed: data.selectedFailed,
              isCanceled: data.selectedCanceled,
              inProgress: data.selectedInProgress,
            },
            limit: data.itemsPerPage || 10,
            page: data.page || 1,
          };
        } else {
          params = {
            filterBy: {
              isFinished: data.selectedSuccessful,
              isFailed: data.selectedFailed,
              isCanceled: data.selectedCanceled,
              inProgress: data.selectedInProgress,
              device: data.search,
            },
            limit: data.itemsPerPage || 10,
            page: data.page || 1,
          };
        }
        const list = await DeployDetails.fetchDeploy({ params }, data.id);
        commit('SET_DEPLOY_DETAILS_LIST', list);
        commit('SET_DEPLOY_DETAILS_COUNT', list.count);
      }
    },
    updated_task({ commit }, payload) {
      commit('UPDATE_TASK', payload.params);
    },
    updated_campaign({ commit }, payload) {
      commit('UPDATE_CAMPAIGNS', payload.params);
    },
    updated_finished_campaign({ commit }, payload) {
      commit('FINISHED_CAMPAIGN', payload.params.data);
    },
    clear_deploy_details_list_and_count({ commit }) {
      commit('SET_DEPLOY_DETAILS_LIST', { data: [] });
      commit('SET_DEPLOY_DETAILS_COUNT', 0);
    },
    async getLog({ commit }, content) {
      commit('SET_CAMPAIGN', content);
      const log = await DeployDetails.getLog(content.contentId);
      commit('SET_CONTENT_LOG', log);
    },
    async reset_task({ commit }, data) {
      const list = await DeployDetails.resetTask(data);
      commit('UPDATE_TASK', list);
    },
    async reset_all_tasks({ commit }, data) {
      const list = await DeployDetails.resetAllTasks(data);
      commit('UPDATE_TASK', list);
    },
    async mark_task_as_fail({ commit }, data) {
      const task = await DeployDetails.markTaskAsFail(data);
      commit('UPDATE_TASK', task);
    },
    async mark_task_as_success({ commit }, data) {
      const task = await DeployDetails.markTaskAsSuccess(data);
      commit('UPDATE_TASK', task);
    },
    // eslint-disable-next-line no-unused-vars
    async get_bulk_operation({ commit }, campId) {
      const content = await DeployDetails.getBulkOperationCampaign(campId);
      commit('SET_BULK_OPERATION', content);
    },
  },
};
