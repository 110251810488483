/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import Axios from '@/plugins/axios';
import store from '@/store';
import mqtt from '@/plugins/mqtt';

const METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

export default class BaseApiService {
  get(url, options, auth) {
    return this.fetch({
      method: METHODS.GET, url, ...options, ...auth,
    });
  }

  post(url, data, options, auth) {
    return this.fetch({
      method: METHODS.POST, url, data, ...options, ...auth,
    });
  }

  put(url, data, options, auth) {
    return this.fetch({
      method: METHODS.PUT, url, data, ...options, ...auth,
    });
  }

  delete(url, options, auth) {
    return this.fetch({
      method: METHODS.DELETE, url, ...options, ...auth,
    });
  }

  patch(url, data, options, auth) {
    return this.fetch({
      method: METHODS.PATCH, url, data, ...options, ...auth,
    });
  }

  deleteWithParams(url, data, options, auth) {
    return this.fetch({
      method: METHODS.DELETE, url, data, ...options, ...auth,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async fetch(axiosFields) {
    const res = await Axios(axiosFields);
    if (mqtt.isConnected()) {
      await store.dispatch('auth/is_services_unavailable', { isUnavailable: false });
    }
    return res && res.data ? res.data : res;
  }
}
