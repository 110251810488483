/* eslint-disable no-useless-catch */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import { LabelsApiService } from '@/services/api';
import LabelModel from '@/model/label/label.model';
import GroupLabelModel from '@/model/label/groupLabel.model';
import store from '@/store';

export default {
  state: {
    labels: [],
    labelsForAutocomplete: [],
    count: 0,
    label: new LabelModel({}),
    groupLabels: [],
    selectedGroupLabel: [],
    countGroupedLabels: 0,
    showFixCaseDialog: false,
    query: {},
    delete: false,
    labelsList: [],
  },
  mutations: {
    SET_LABELS(state, labels) {
      state.labels = labels.data.map((label) => new LabelModel(label));
      state.count = labels.count;
    },
    SET_LABELS_LAZY(state, labels) {
      state.count = labels.count;
      const filteredArr = labels.data.filter((el) => {
        let duplicate = [];
        duplicate = state.labelsList.find(
          (label) => label.key === el.key && label.value === el.value,
        );
        return !duplicate;
      });
      state.labelsList.push(...filteredArr.map((label) => new LabelModel(label)));
    },
    SET_LABELS_AUTOCOMPLETE(state, labels) {
      state.labelsForAutocomplete = labels.data.map((label) => new LabelModel(label));
    },
    REMOVE_LABEL(state, id) {
      state.labels.splice(state.labels.findIndex((label) => label._id === id), 1);
      state.labelsList.splice(state.labelsList.findIndex((label) => label._id === id), 1);
    },
    UPDATE_LABEL(state, label) {
      state.label = new LabelModel(label);
    },
    CREATE_LABEL(state, label) {
      state.labels.push(new LabelModel(label));
    },
    SELECTED_LABEL(state, label) {
      state.label = new LabelModel(label || {});
    },
    GROUP_LABEL(state, labels) {
      state.groupLabels = labels.data.map((label) => new GroupLabelModel(label));
      state.countGroupedLabels = labels.count;
    },
    SHOW_FIX_CASE_DIALOG(state, value) {
      state.showFixCaseDialog = value;
    },
    SELECTED_GROUP_LABEL(state, groupLabel) {
      const labelListIndex = state.selectedGroupLabel.findIndex(
        (group) => group.key === groupLabel.key,
      );
      if (labelListIndex !== -1) {
        state.selectedGroupLabel.splice(labelListIndex, 1);
        return;
      }
      state.selectedGroupLabel.push(groupLabel);
    },

    REMOVE_SELECTED_GROUP_LABEL(state, groupLabel) {
      state.selectedGroupLabel.splice(state.selectedGroupLabel.findIndex(
        (group) => group.key === groupLabel.key,
      ), 1);
    },
    RESET_SELECTED_GROUP_LABEL(state) {
      state.selectedGroupLabel = [];
    },
    SET_QUERY(state, params) {
      params.sortBy = Array.isArray(params.sortBy[0]) ? params.sortBy[0] : params.sortBy;
      state.query = params;
    },
  },
  getters: {
    list: (state) => state.labels,
    labelsList: (state) => state.labelsList,
    count: (state) => state.count,
    label: (state) => state.label,
    keys: (state) => state.labels.map((label) => label.key),
    values: (state) => state.labels.map((label) => label.value),
    groupLabels: (state) => state.groupLabels,
    countGroupedLabels: (state) => state.countGroupedLabels,
    selectedGroupLabel: (state) => state.selectedGroupLabel,
    showFixCaseDialog: (state) => state.showFixCaseDialog,
    getQuery: (state) => state.query,
  },

  actions: {
    async fetch({ state, commit }, parameters = {}) {
      function handleOrder({ sortBy, sortDesc }) {
        if (!sortBy || !sortDesc) {
          return { createdAt: 'desc' };
        }

        if (!sortBy.length && !sortDesc.length) {
          return { createdAt: 'desc' };
        }
        if (Array.isArray(sortBy[0])) {
          const newSortBy = sortBy[0];
          return { [newSortBy[0] ? newSortBy[0] : 'createdAt']: sortDesc[0] ? 'desc' : 'asc' };
        }
        return { [sortBy[0] ? sortBy[0] : 'createdAt']: sortDesc[0] ? 'desc' : 'asc' };
      }
      if (state.delete && state.labels.length === 0 && parameters.page > 1) {
        parameters.page -= 1;
      }
      const params = {
        filterBy: parameters.search ? { key: parameters.search } : parameters.filterBy || {},
        limit: parameters.itemsPerPage || 10,
        page: parameters.page || 1,
        order: handleOrder(parameters),
      };

      commit('SET_LABELS', await LabelsApiService.fetchLabels({ params }));
    },
    async fetchLabels({ state, commit }, parameters = {}) {
      function handleOrder({ sortBy, sortDesc }) {
        if (!sortBy || !sortDesc) {
          return { createdAt: 'desc' };
        }

        if (!sortBy.length && !sortDesc.length) {
          return { createdAt: 'desc' };
        }
        if (Array.isArray(sortBy[0])) {
          const newSortBy = sortBy[0];
          return { [newSortBy[0] ? newSortBy[0] : 'createdAt']: sortDesc[0] ? 'desc' : 'asc' };
        }
        return { [sortBy[0] ? sortBy[0] : 'createdAt']: sortDesc[0] ? 'desc' : 'asc' };
      }
      if (state.delete && state.labels.length === 0 && parameters.page > 1) {
        parameters.page -= 1;
      }
      const params = {
        filterBy: parameters.search ? { key: parameters.search } : parameters.filterBy || {},
        limit: parameters.itemsPerPage || 10,
        page: parameters.page || 1,
        order: handleOrder(parameters),
      };

      commit('SET_LABELS_LAZY', await LabelsApiService.fetchLabels({ params }));
    },
    async fetchForAutocomplete({ commit }, parameters = {}) {
      const params = {
        filterBy: parameters.filterBy || {},
        limit: parameters.itemsPerPage || 10,
        page: parameters.page || 1,
        order: parameters.order || { createdAt: 'desc' },
      };

      commit('SET_LABELS_AUTOCOMPLETE', await LabelsApiService.fetchLabels({ params }));
    },
    save_query({ commit }, params) {
      commit('SET_QUERY', params);
    },
    async remove({ state, commit }, { _id }) {
      state.delete = true;
      await LabelsApiService.remove(_id);
      commit('REMOVE_LABEL', _id);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'labels.list.deletedItem',
        color: 'success',
        showClose: true,
      });
    },
    async update({ commit }, label) {
      try {
        await LabelsApiService.update(label);
        commit('UPDATE_LABEL', label);
      } catch (e) {
        throw e;
      }
    },
    async create({ commit }, label) {
      try {
        const createdLabel = await LabelsApiService.create(label);
        commit('CREATE_LABEL', createdLabel);
      } catch (e) {
        throw e;
      }
    },
    async getById({ commit }, id) {
      let label;
      if (id) {
        label = await LabelsApiService.getById(id);
      }
      commit('SELECTED_LABEL', label);
    },
    async group_labels({ commit }, parameters) {
      const params = {
        limit: parameters.limit || 10,
        page: parameters.page || 1,
        filterBy: parameters.search ? { key: parameters.search } : parameters.filterBy || {},
      };
      commit('GROUP_LABEL', await LabelsApiService.groupLabels({ params }));
    },
    select_label_group({ commit }, groupLabel) {
      commit('SELECTED_GROUP_LABEL', groupLabel);
    },
    remove_selected_group_label({ commit }, groupLabel) {
      commit('REMOVE_SELECTED_GROUP_LABEL', groupLabel);
    },
    show_fix_case_dialog({ commit }, value) {
      commit('SHOW_FIX_CASE_DIALOG', value);
    },
    // eslint-disable-next-line no-empty-pattern
    async merge_labels({ }, params) {
      await LabelsApiService.mergeLabels(params);
    },
    reset_selected_group_label({ commit }) {
      commit('RESET_SELECTED_GROUP_LABEL');
    },
  },
};
