/*
*  TTTech nerve-management-system
*  Copyright(c) 2023. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import { DNA_STATUSES } from '@/constants';

export default class DnaModel {
  /**
   * Creates an instance of DnaModel.
   * @property {string} fileName - DNA target configuration file name
   * @property {string} status - DNA status
   * @property {string} updated - Last DNA state change
   */
  constructor(data = {}) {
    this.fileName = data.fileName || DNA_STATUSES.n_a;
    this.status = data.status || DNA_STATUSES.n_a;
    this.updated = data.updated ? new Date(data.updated).toLocaleString('en-GB') : DNA_STATUSES.n_a;
    this.created = data.createdBy && data.createdBy.date ? new Date(data.createdBy.date).toLocaleString('en-GB') : '';
  }

  set status(status) {
    if (status) {
      if (status === DNA_STATUSES.n_a) {
        this._status = status;
        return;
      }
      const lowerCaseStatus = status.toLowerCase();
      this._status = lowerCaseStatus.charAt(0).toUpperCase() + lowerCaseStatus.slice(1);
    }
  }

  get status() {
    return this._status;
  }
}
