/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import shared from '@/helpers/shared';
import i18n from '@/i18n';

/**
 * Statuses represented in integer values
 * @type {Object}
 * @property {number} NO_CODESYS - No codesys
 * @property {number} IDLE - Idle
 * @property {number} CREATING - Creating
 * @property {number} REMOVING - Removing
 * @property {number} SUSPENDING - Suspending
 * @property {number} SUSPENDED - Suspended
 * @property {number} STARTING - Starting
 * @property {number} RESTARTING - Restarting
 * @property {number} RESUMING - Resuming
 * @property {number} STARTED - Started
 * @property {number} STOPPING - Stopping
 * @property {number} STOPPED - Stopped
 * @property {number} ERROR - Error
 * @property {number} REMOVING_FAILED - Removing failed
 */
export const STATUSES = {
  NO_CODESYS: -1,
  IDLE: 0,
  CREATING: 1,
  REMOVING: 2,
  SUSPENDING: 3,
  SUSPENDED: 4,
  STARTING: 5,
  RESTARTING: 6,
  RESUMING: 7,
  STARTED: 8,
  STOPPING: 9,
  STOPPED: 10,
  ERROR: 11,
  REMOVING_FAILED: 12,
  PARTIALLY_RUNNING: 13,
};

/**
 * Commands represented in string values
 * @type {Object}
 * @property {string} DEPLOY - Deploy
 * @property {string} UNDEPLOY - Undeploy
 * @property {string} STOP - Stop
 * @property {string} START - Start
 * @property {string} SUSPEND - Suspend
 * @property {string} RESUME - Resume
 * @property {string} RESTART - Restart
 * @property {string} UNINSTALL - Uninstall
 */
export const COMMANDS = {
  DEPLOY: 'DEPLOY',
  UNDEPLOY: 'UNDEPLOY',
  STOP: 'STOP',
  START: 'START',
  SUSPEND: 'SUSPEND',
  RESUME: 'RESUME',
  RESTART: 'RESTART',
  UNINSTALL: 'UNINSTALL',
};

export const BACKUP_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  ABORTED: 'ABORTED',
  STOPPING: 'STOPPING',
};

/**
 * Array of predefined controls
 * @type {Array}
 */
export const CONTROLS = [
  {
    command: COMMANDS.START,
    name: 'Start',
    icon: 'mdi-play',
    enableFor: [STATUSES.STOPPED],
    visibleFor: [['all'],
      [
        STATUSES.STARTED,
        STATUSES.STOPPED,
        STATUSES.STARTING,
        STATUSES.STOPPING,
        STATUSES.RESUMING,
        STATUSES.SUSPENDING,
        STATUSES.RESTARTING,
      ],
    ],
    updateTo: STATUSES.STARTING, // this will be used for forced status updates
  }, {
    command: COMMANDS.STOP,
    name: 'Stop',
    icon: 'mdi-stop',
    enableFor: [STATUSES.STARTED],
    visibleFor: [['vm', 'docker'], ['all']],
    updateTo: STATUSES.STOPPING,
  }, {
    command: COMMANDS.SUSPEND,
    name: 'Pause',
    icon: 'mdi-pause',
    enableFor: [STATUSES.STARTED],
    visibleFor: [['vm', 'docker'],
      [
        STATUSES.STARTED,
        STATUSES.SUSPENDED,
        STATUSES.STOPPED,
        STATUSES.STARTING,
        STATUSES.STOPPING,
        STATUSES.RESUMING,
        STATUSES.SUSPENDING,
        STATUSES.RESTARTING,
      ],
    ],
    updateTo: STATUSES.SUSPENDING,
  }, {
    command: COMMANDS.RESUME,
    name: 'Play',
    icon: 'mdi-play',
    enableFor: [STATUSES.SUSPENDED],
    visibleFor: [['vm', 'docker'], [STATUSES.SUSPENDED]],
    updateTo: STATUSES.RESUMING,
  }, {
    command: COMMANDS.RESTART,
    name: 'Restart',
    icon: 'mdi-restart',
    enableFor: [STATUSES.STARTED],
    visibleFor: [['vm', 'docker'], ['all']],
    updateTo: STATUSES.RESTARTING,
  }, {
    command: COMMANDS.UNDEPLOY,
    name: 'Undeploy',
    enableFor: [],
    visibleFor: [[], []],
    updateTo: STATUSES.REMOVING,
  },
];

export const STATS = {
  RAM: 'RAMLoad',
  CPU: 'CPULoad',
};

export const PROPERTY_LIST_NAMES = {
  COMMAND: 'Command',
  MESSAGE: 'Message',
  STATE: 'State',
  VALUE: 'Value',
  CONFIGURATION_UPDATE_STATUS: 'ConfigurationUpdateStatus',
  VIDEO_OUTPUT: 'VideoOutput',
  CPU_NUMBER: 'CPUNumber',
  MEMORY: 'Memory',
};

export const SERVICE_LIST_TYPES = {
  MONITOR: 'SystemMonitorService',
  CONTROL: 'VMControlService',
  WISE: 'WiseConfigurationService',
};

const DEVICE_TYPES = {
  DOCKER: 'docker',
  CODESYS: 'codesys',
  VM: 'vm',
  COMPOSE: 'docker-compose',
};

const VM_TYPE = 'KVM Virtual Machine';

export default class DeployedWorkloadModel {
  /**
   * @description Node device sub model
   * @param data
   */
  constructor(data = {}) {
    this.id = data.deviceId || data.id || '';
    this.workloadId = this._workloadId || data.workloadId || data.id || '';
    this.name = data.device_name || data.name || data.data.name || '';
    this.timestamp = data.timestamp || '';
    this.type = data;
    this.currentStatus = data;
    this.stats = data;
    this.message = data;
    this.version = data;
    this.deployTime = data;
    this.workloadVersionName = data;
    this.lastChange = data;
    this.versionId = data;
    this.configurationUpdateInfo = data.configurationUpdateInfo || {};
    this.configurationUpdateStatus = data;
    this.videoOutput = data;
    this.resources = data;
    this.features = data;
    this.memory = data;
    if (this.type === 'docker' || this.type === 'docker-compose') {
      this.environmentVariables = data;
      this.portList = data;
    }
  }

  set memory(data) {
    if (!data.memory) {
      if (this.type === 'docker' || this.type === 'docker-compose') {
        const cpu = data.wlStats && data.wlStats.cpu ? `${Math.ceil(data.cpu)}` : '0';
        const ram = data.wlStats && data.wlStats.ram ? `${Math.ceil(data.cpu)}` : '0';
        this._memory = { cpu, ram };
      } else if (this.type === 'vm') {
        const cpu = data.wlStats && data.wlStats.cpu ? `${Math.ceil(data.cpu)}` : '0';
        this._memory = { cpu, ram: '/' };
      } else {
        this._memory = { cpu: '/', ram: '/' };
      }
    }

    if (data.memory) {
      this._memory = data.memory;
      if (data.wlStats && typeof data.wlStats.cpu !== 'undefined') {
        this._memory.cpu = `${Math.ceil(data.wlStats.cpu)}`;
      }
      if (data.wlStats && typeof data.wlStats.ram !== 'undefined') {
        this._memory.ram = `${Math.ceil(data.wlStats.ram)}`;
      }
    }
  }

  get memory() {
    return this._memory;
  }

  get environmentVariables() {
    return this._environmentVariables;
  }

  set environmentVariables(data) {
    if (data.service_list) {
      const control = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.WISE);
      const propertyValue = control.property_list
        .find((pl) => pl.name === PROPERTY_LIST_NAMES.VALUE);
      const { envVars } = JSON.parse(propertyValue.value);

      this._environmentVariables = envVars ? envVars.map((element) => {
        if (typeof element === 'string') {
          element = { variable: element.split('=')[0], value: element.split('=')[1] };
        }
        return element;
      }) : [];
      return;
    }
    if (data.envVars && data.envVars.length > 0) {
      this._environmentVariables = data.envVars.map((element) => {
        if (typeof element === 'string') {
          element = { variable: element.split('=')[0], value: element.split('=')[1] };
        }
        return element;
      });
      return;
    }
    this._environmentVariables = data._environmentVariables || [];
  }

  get portList() {
    return this._portList;
  }

  set portList(data) {
    if (data.service_list) {
      const control = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.WISE);
      const propertyValue = control.property_list
        .find((pl) => pl.name === PROPERTY_LIST_NAMES.VALUE);
      const { ports } = JSON.parse(propertyValue.value);
      this._portList = ports ? ports.map((element) => {
        element.protocol = element.protocol.toUpperCase();
        return element;
      }) : [];
      return;
    }
    if (data.ports && data.ports.length > 0) {
      this._portList = data.ports.map((element) => {
        element.protocol = element.protocol.toUpperCase();
        return element;
      });
      return;
    }
    this._portList = data._portList || [];
  }

  set resources(data) {
    if (data.service_list) {
      const control = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.CONTROL);
      const propertyCpu = control.property_list
        .find((pl) => pl.name === PROPERTY_LIST_NAMES.CPU_NUMBER);
      const propertyMem = control.property_list
        .find((pl) => pl.name === PROPERTY_LIST_NAMES.MEMORY);
      const obj = {};
      if (propertyCpu) {
        obj.cpu = propertyCpu.value;
      }
      if (propertyMem) {
        obj.memory = propertyMem.value;
      }
      if (obj !== {}) {
        this._resources = obj;
      }
      return;
    }
    if (data._resources) {
      this._resources = data._resources;
    }
  }

  get resources() {
    return this._resources;
  }

  set type(data) {
    if (typeof data === 'string') { // hack for assigning
      this._type = data;
      return;
    }

    if (data.device_model_name === VM_TYPE) {
      this._type = DEVICE_TYPES.VM;
      return;
    }
    this._type = data.device_model_name || data.type;
  }

  get type() {
    return this._type && this._type.toLowerCase().replace(' ', '-');
  }

  set currentStatus(data) {
    if (data.service_list) {
      const control = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.CONTROL);
      const property = control.property_list.find((pl) => pl.name === PROPERTY_LIST_NAMES.STATE);
      this._currentStatus = shared.getKeyByValue(STATUSES, property.value).toLowerCase();
      return;
    }

    if (data.wiseDeviceStatus) {
      this._currentStatus = shared.getKeyByValue(STATUSES, data.wiseDeviceStatus).toLowerCase();
      return;
    }

    this._currentStatus = data.currentStatus;
  }

  get currentStatus() {
    return this._currentStatus && this._currentStatus.toLowerCase();
  }

  set stats(data) {
    if (data.stats) {
      this._stats = data.stats;
    }

    if (data.service_list) {
      const monitor = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.MONITOR);
      // when there is no already set information and no information from specific service
      // just create stats object needed by workload control page
      if (!monitor) {
        this._stats = { ram: 0, cpu: 0 };
      } else {
        const ram = monitor.property_list.find((pL) => pL.name === STATS.RAM);
        const cpu = monitor.property_list.find((pL) => pL.name === STATS.CPU);
        this._stats = { ram: ram.value, cpu: cpu.value };
      }
    }
    if (typeof this._stats === 'undefined') {
      this._stats = { ram: 0, cpu: 0 };
    }
  }

  get stats() {
    return this._stats;
  }

  set message(data) {
    if (data.message) {
      this._message = data.message;
    }

    if (data.service_list) {
      const control = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.CONTROL);
      this._message = control.property_list
        .find((pl) => pl.name === PROPERTY_LIST_NAMES.MESSAGE).value;
    }
  }

  get message() {
    return this._message;
  }

  set version(data) {
    if (data.version) {
      this._version = data.version;
    }

    if (data.service_list) {
      const wise = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.WISE);
      const prop = wise.property_list.find((pl) => pl.name === PROPERTY_LIST_NAMES.VALUE);
      const parsed = JSON.parse(prop.value);
      this._version = parsed.workloadVersionName
        ? parsed.workloadVersionName : parsed.workloadVersion;
    }
  }

  get version() {
    return this._version;
  }

  set deployTime(data) {
    if (data.deployTime) {
      this._deployTime = typeof data.deployTime === 'string' && data.deployTime.includes('/') ? data.deployTime : new Date(data.deployTime).toLocaleString('en-GB');
    }

    if (data.service_list) {
      const wise = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.WISE);
      const prop = wise.property_list.find((pl) => pl.name === PROPERTY_LIST_NAMES.VALUE);
      const parsed = JSON.parse(prop.value);
      if (!parsed.deployTime) {
        this._deployTime = i18n.t('nerveSimpleTable.n/a');
        return;
      }
      this._deployTime = new Date(parsed.deployTime).toLocaleString('en-GB');
    }
  }

  get deployTime() {
    return this._deployTime;
  }

  set lastChange(data) {
    if (data.lastChange) {
      this._lastChange = data.lastChange.includes('/') ? data.lastChange : new Date(data.lastChange).toLocaleString('en-GB');
    }

    if (data.service_list) {
      const control = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.CONTROL);
      const prop = control.property_list.find((pl) => pl.name === PROPERTY_LIST_NAMES.STATE);
      this._lastChange = new Date(prop.timestamp).toLocaleString('en-GB');
      return;
    }

    if (data.wiseDeviceStatus) {
      this._lastChange = new Date(data.statusChangedTimestamp).toLocaleString('en-GB');
      return;
    }

    this._lastChange = data.lastChange;
  }

  get lastChange() {
    return this._lastChange;
  }

  set workloadVersionName(data) {
    if (data.workloadVersionName) {
      this._workloadVersionName = data.workloadVersionName;
    }

    if (data.service_list) {
      const wise = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.WISE);
      const prop = wise.property_list.find((pl) => pl.name === PROPERTY_LIST_NAMES.VALUE);
      const parsed = JSON.parse(prop.value);
      this._workloadVersionName = parsed.workloadVersionName || '';
    }
  }

  get workloadVersionName() {
    return this._workloadVersionName;
  }

  set versionId(data) {
    if (data.versionId) {
      this._versionId = data.versionId;
    }

    if (data.service_list) {
      const wise = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.WISE);
      const prop = wise.property_list.find((pl) => pl.name === PROPERTY_LIST_NAMES.VALUE);
      const parsed = JSON.parse(prop.value);
      this._versionId = parsed.versionId;
    }
  }

  get versionId() {
    return this._versionId;
  }

  set configurationUpdateInfo(data) {
    this._configurationUpdateInfo = data;
  }

  get configurationUpdateInfo() {
    return this._configurationUpdateInfo;
  }

  set configurationUpdateStatus(data) {
    if (data.service_list) {
      const wise = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.WISE);
      const prop = wise.property_list
        .find((pl) => pl.name === PROPERTY_LIST_NAMES.CONFIGURATION_UPDATE_STATUS);
      this._configurationUpdateStatus = prop && typeof prop === 'object' ? prop.value : {};
      return;
    }

    if (data.configurationUpdateStatus) {
      this._configurationUpdateStatus = data.configurationUpdateStatus;
    }
  }

  get configurationUpdateStatus() {
    return this._configurationUpdateStatus;
  }

  set videoOutput(data) {
    if (data.service_list) {
      const wise = data.service_list.find((sl) => sl.type === SERVICE_LIST_TYPES.WISE);
      const prop = wise.property_list.find((pl) => pl.name === PROPERTY_LIST_NAMES.VIDEO_OUTPUT);
      this._videoOutput = prop && typeof prop === 'object' ? prop.value : {};
      return;
    }

    if (data.videooutput) {
      this._videoOutput = data.videooutput;
      return;
    }
    if (data.videoOutput) {
      this._videoOutput = data.videoOutput;
    }
  }

  get videoOutput() {
    return this._videoOutput;
  }

  set features(data) {
    if (data.features) {
      this._features = data.features;
    }
  }

  get features() {
    return this._features;
  }

  isDisabled() {
    return [STATUSES.IDLE, STATUSES.CREATING, STATUSES.REMOVING].includes(this.currentStatus);
  }

  setStats(property, value) {
    if (!this._stats) {
      this._stats = {};
    }

    if (this._stats[property.toLowerCase()]) {
      this._stats[property.toLowerCase()] = value;
    }
  }

  isInTransitionalStatus() {
    const status = this.currentStatus ? this.currentStatus.toUpperCase() : '';
    return [
      STATUSES.STARTING,
      STATUSES.STOPPING,
      STATUSES.RESUMING,
      STATUSES.RESTARTING,
      STATUSES.REMOVING,
      STATUSES.CREATING,
    ].includes(STATUSES[status]);
  }

  isOnline() {
    const status = this.currentStatus ? this.currentStatus.toUpperCase() : '';
    return STATUSES[status] === STATUSES.STARTED;
  }
}
