/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const PATHS = {
  LABELS: 'nerve/labels',
  LIST: 'nerve/labels/list',
  GROUP: 'nerve/labels/group',
  MERGE: 'nerve/labels/merge',
};

class LabelsApiService extends BaseApiService {
  fetchLabels(params) {
    return this.get(PATHS.LIST, params, { authRequired: true });
  }

  remove(id) {
    return this.delete(`${PATHS.LABELS}/${id}`, { authRequired: true });
  }

  update(label) {
    return this.patch(`${PATHS.LABELS}/${label._id}`, { _id: label._id, key: label.key, value: label.value }, { authRequired: true });
  }

  create(params) {
    return this.post(`${PATHS.LABELS}`, params, { authRequired: true });
  }

  getById(id) {
    return this.get(`${PATHS.LABELS}/${id}`, { authRequired: true });
  }

  groupLabels(params) {
    return this.get(`${PATHS.GROUP}`, params, { authRequired: true });
  }

  mergeLabels(params) {
    return this.patch(`${PATHS.MERGE}`, params, { authRequired: true });
  }
}

export default new LabelsApiService();
