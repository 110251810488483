/*
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/

export default class AuthModel {
  /**
   * Creates an instance of Auth user.
   * @class UserModel
   * @property {string} id - Auth user id
   * @property {string} firstName - First name of logged user
   * @property {string} lastName - Last name of logged user
   * @property {string} preferredLanguage - preferred language of logged user
   * @property {string} type - Type of logged user (ldap or local)
   * @property {string} username - Username of logged user
   */
  constructor(data = {}) {
    this.id = data.id || '';
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.preferredLanguage = data.preferredLanguage || 'en_EN';
    this.type = data.type || '';
    this.username = data.username || '';
    this.profileImgURL = data.profileImgURL || '';
  }
}
