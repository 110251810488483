/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import BaseApiService from './base.api-service';

const URL = '/nerve/workload/node';

class SnapshotApiService extends BaseApiService {
  fetchSnapshots(params) {
    return this.get(`${URL}/${params.serialNumber}/snapshots/${params.deviceId}`, { authRequired: true });
  }

  create(params) {
    return this.post(`${URL}/${params.serialNumber}/snapshots/${params.deviceId}`, params.snapshot, { authRequired: true });
  }

  remove(params) {
    return this.deleteWithParams(`${URL}/${params.serialNumber}/snapshots/${params.deviceId}`, { name: params.snapshotName }, { authRequired: true });
  }

  restore(params) {
    return this.put(`${URL}/${params.serialNumber}/snapshots/${params.deviceId}`, { name: params.snapshot.name }, { authRequired: true });
  }

  schedule(params) {
    return this.post(`${URL}/${params.serialNumber}/snapshots/schedule/${params.deviceId}`, params.schedule, { authRequired: true });
  }

  removeSchedule(params) {
    return this.delete(`${URL}/${params.serialNumber}/snapshots/schedule/${params.deviceId}`, { authRequired: true });
  }
}
export default new SnapshotApiService();
