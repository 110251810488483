import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/lang/index';

Vue.use(VueI18n);

let storedLang = localStorage.getItem('lang');
if (!storedLang && Array.isArray(navigator.languages)) {
  storedLang = navigator.languages.find((lang) => messages[lang.substr(0, 2)]);
  storedLang = storedLang.substring(0, 2);
}

const i18n = new VueI18n({
  locale: storedLang || 'en',
  fallbackLocale: 'en',
  messages,
});

export default i18n;
