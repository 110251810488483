/*
*  TTTech nerve-management-system
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/

export default class NodeUpdateListModel {
  /**
     * Creates an instance of NodeUpdateListModel.
     * @class NodeUpdateListModel
     * @property {string} name - name
     * @property {string} releaseDate - data
     * @property {string} id - id
     * @property {string} compatibleVersions - versions
     */
  constructor(data = {}) {
    this.id = data._id || '';
    this.name = data.name || '';
    this.releaseDate = (data.releaseDate !== null) ? (data.releaseDate.toString().slice(0, 10).split('-').reverse()
      .join('/')) : '';
    this.compatibleVersions = data.compatibleVersions || '';
  }
}
