<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <nerve-dialog
    v-model="dialogState"
    persistent
    scrollable
    max-width="500"
    @keydown.esc="closeDialog()"
    @keydown.enter="closeDialog()"
  >
    <v-card class="api-message">
      <v-card-title>
        {{ $t(dialogParams.title) }}
      </v-card-title>
      <v-card-text>{{ $t(dialogParams.text) }}
        {{ (dialogParams.list || []).join(', ') }}</v-card-text>
      <v-card-actions v-if="!dialogParams.noClose">
        <nerve-button
          id="iiotApiMessageBoxCloseDialogBtn"
          :text="$t('errorDialog.okBtn')"
          type-of-btn="close"
          size="normal"
          autofocus
          data-cy="iiotApiMessageBoxCloseDialogBtn"
          @click-event="closeDialog()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';

export default {
  name: 'ApiMessageBox',
  components: { NerveButton, NerveDialog },
  computed: {
    dialogState: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getMessageBoxState']();
      },
      set() {},
    },
    dialogParams: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getMessageBoxParams']();
      },
      set() {},
    },
  },
  methods: {
    closeDialog() {
      this.$store.dispatch('utils/_api_request_handler/close_message_box');
    },
  },
};
</script>
<style lang="scss" scoped>
 .api-message {
    max-height: 460px !important;
    min-height: 320px !important;
  }
</style>
