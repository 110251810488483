/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/

export default class NotificationModel {
  /**
   * Creates an instance of NotificationModel.
   * @class NotificationModel
   * @property {string} _id - Notification id
   * @property {string} headerText - Header text
   * @property {string} textMessage - Message notification
   * @property {boolean} showBeforeLogin - Boolean flag: displayed before/after login
   * @property {string} backgroundImage - The path to the uploaded background image
   *                                      for dialog notification
   * @property {boolean} active - Indicator to explain which notification will be shown to users
   */
  constructor(data = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._id = data._id || '';
    this.headerText = data.headerText || '';
    this.textMessage = data.textMessage || '';
    this.showBeforeLogin = data.showBeforeLogin || false;
    this.backgroundImage = data.backgroundImage || '';
    this.active = data.active || false;
  }
}
