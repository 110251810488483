/* eslint-disable no-underscore-dangle */
import BaseApiService from './base.api-service';

const URL = 'nerve/rbac';
const ENDPOINTS = {
  GET_ALL_ROLES: `${URL}/roles`,
  CRUD_ROLE: `${URL}/roles`,
  GET_ALL_PERMISSIONS: `${URL}/permissions`,
  GET_PERMISSION: `${URL}/permissions/`,
  GET_ALL_CATEGORIES: `${URL}/categories`,
};

class RolesApiService extends BaseApiService {
  getAllRoles(params) {
    return this.get(ENDPOINTS.GET_ALL_ROLES, params, { authRequired: true });
  }

  getRole(roleId) {
    return this.get(`${ENDPOINTS.CRUD_ROLE}/${roleId}`, { authRequired: true });
  }

  createRole(data) {
    return this.post(ENDPOINTS.CRUD_ROLE, data, { authRequired: true });
  }

  updateRole(data) {
    return this.patch(`${ENDPOINTS.CRUD_ROLE}/${data._id}`, data, { authRequired: true });
  }

  deleteRole(roleId) {
    return this.delete(`${ENDPOINTS.CRUD_ROLE}/${roleId}`, { authRequired: true });
  }

  getPermissions(data) {
    let params = {};
    if (data) {
      params = {
        filterBy: { name: data.searchText || '' },
        categories: data.categories || [],
      };
    }
    return this.get(`${ENDPOINTS.GET_ALL_PERMISSIONS}?categories=${params.categories}&filterBy=${JSON.stringify(params.filterBy)}`, { authRequired: true });
  }

  getPermission(permissionId) {
    return this.get(`${ENDPOINTS.GET_PERMISSION}/${permissionId}`, { authRequired: true });
  }

  getCategories() {
    return this.get(ENDPOINTS.GET_ALL_CATEGORIES, { authRequired: true });
  }
}

export default new RolesApiService();
