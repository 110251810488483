<template>
  <v-app v-if="$route.name">
    <v-idle
      v-if="isLoggedIn && $route.name !== 'Login'"
      :duration="DURATION"
      class="idle"
      @idle="goToLogin"
    />
    <core-toolbar v-if="isLoggedIn
      && $route.name !== 'Login'
      && $route.name !== 'Guacamole Client'"/>
    <core-drawer
      v-if="isLoggedIn
      && $route.name !== 'Login'
      && $route.name !== 'Guacamole Client'"/>
    <core-view class="vertical-scroll"/>
  </v-app>
</template>
<script>

import CoreView from '@/layout/View.vue';
import CoreToolbar from '@/layout/Toolbar.vue';
import CoreDrawer from '@/layout/Drawer.vue';
import { DURATION } from '@/constants';
import Logger from '@/utils/logger';

export default {
  components: {
    CoreView,
    CoreToolbar,
    CoreDrawer,
  },
  data: () => ({
    DURATION,
  }),
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']();
    },
  },
  methods: {
    async goToLogin() {
      try {
        await this.$store.dispatch('auth/logout');
        this.$router.push({ name: 'Login' });
        await this.$store.dispatch('node-tree/setNodeListToEmpty');
        this.$store.dispatch('node-tree/set_last_change_nodes_backup_to_empty');
      } catch (e) {
        Logger.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
.idle {
  display: none;
}
.vertical-scroll {
  overflow-y: auto !important;
  height: 250px;
}
</style>
