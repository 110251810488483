/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/*
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import moment from 'moment';

export const ICONS = {
  DOCKER: '$vuetify.icons.iconDoc',
  VM: '$vuetify.icons.iconVm',
  CODESYS: '$vuetify.icons.iconCod',
};

export const COLORS = {
  DOCKER: '#ff9a00',
  VM: '#3810f2',
  CODESYS: '#e83e8c',
};

export default class DeployLogModel {
  /**
       * Creates an instance of DeployLogModel.
       * @param {Array} data - DeployLogModel list
       * @class DeployLogModel
       * @property {string} name - Deployment name
       * @property {string} id - Deployment id
       * @property {boolean} action - Deployment action
       * @property {string} started - Deployment started
       * @property {string} finished - Deployment finished
       * @property {boolean} type - Deployment type
       * @property {string} typeIcon - Deployment workload icon
       * @property {string} color - Deployment workload color
       * @property {Number} progress - Deployment progress percent
       * @property {string} status - Deployment status
       * @property {string} workloadName - Workload name
       * @property {string} versionName -Version name
       */
  constructor(data = {}) {
    this.name = data.operation_name || '';
    this.id = data._id || '';
    this.action = data.dryRun || false;
    this.started = (data.timeOfOperationStart) ? new Date(data.timeOfOperationStart).toLocaleString('en-GB') : '';
    this.finished = (data.timeOfOperationFinish) ? new Date(data.timeOfOperationFinish).toLocaleString('en-GB') : '';
    this.type = data.contentSubtype || '';
    this.typeIcon = data.contentSubtype === 'docker' ? ICONS.DOCKER : (data.contentSubtype === 'vm') ? ICONS.VM : ICONS.CODESYS;
    this.color = data.contentSubtype === 'docker' ? COLORS.DOCKER : (data.contentSubtype === 'vm') ? COLORS.VM : COLORS.CODESYS;
    this.progress = data.progress
      ? (data.progress).toFixed(2) : (data.campaignOptions.progress).toFixed(2);
    this.status = data.status;
    this.workloadName = data.contentId.content_params[0].params.workloadName || '';
    this.versionName = data.contentId.content_params[0].params.version.name || '';
  }

  get name() {
    return this._name;
  }

  set name(data) {
    if (data.endsWith('AM') || data.endsWith('PM')) {
      data = moment(data, 'MM/DD/YYYY,h:mm:ssA').format('DD/MM/YYYY,HH:mm:ss');
    }
    this._name = data;
  }
}
