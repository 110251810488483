/* eslint-disable max-len */
/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import RemoteConnectionModel from '@/model/node/remoteConnection.model';
import DockerConfigurationStorageModel from '@/model/workloads/docker-configuration-storage.model';
import ComposeServiceModel from '@/model/workloads/compose-service.model';
import LabelBasicModel from '../label/label.basic.model';

export default class ComposeWorkloadVersionModel {
  /**
     * Creates an instance of WorkloadVersionModel.
     * @param {Array} data - WorkloadVersionModel list
     * @class WorkloadVersionModel
     * @property {string} _id - Version id
     * @property {string} name - Version name
     * @property {string} releaseName - Version release name
     * @property {string} created - Version created
     * @property {object} workloadProperties - Workload properties
     * @property {boolean} restartOnConfigurationUpdate - Restart on configuration update
     * @property {boolean} delete - Is deleted version
     * @property {boolean} isDeployable - Is deployable version
     * @property {array} remoteConnections - List of remote connections
     * @property {boolean} firstVolumeAsConfigurationStorage - First volume as configuration storage
     * @property {string} dockerFilePath - Docker file path
     * @property {string} dockerFileOption - Docker file option
     * @property {array} files - Uploaded files
     * @property {string} updatedAt - Version updated
     * @property {string} cloneOf - Id from cloned version
     * @property {object} composeFile - Docker compose file
     * @property {string} versionHash - Hash id of the version
     * @property {object} summarizedFileStatuses - Object of summarized statuses of files (available, cancelled, downloading, failed)
     * @property {boolean} isFailed - Does version contains failed image
     */
  constructor(data = {}) {
    this.id = data.version._id || '';
    this.name = data.version.name || '';
    this.releaseName = this.name;
    this._id = data.version._id || data.version.id || '';
    this.created = data.version.createdAt ? new Date(data.version.createdAt).toLocaleDateString('en-GB') || '' : data.version.created;
    this.selectors = data.version.selectors ? data.version.selectors
      .map((selector) => new LabelBasicModel(selector)) : [];
    this.released = data.version.released || false;
    this.isDownloading = data.version.isDownloading || false;
    this.errorMessage = data.version.errorMessage || '';
    this.deleted = data.version.deleted || false;
    this.isDeployable = data.version.isDeployable || false;
    this.updatedAt = data.version.updatedAt || '';
    this.cloneOf = data.cloneOf;
    this.composeFile = data.version.composeFile || {};
    this.versionHash = data.versionHash || '';
    this.numberOfServices = data.version.numberOfServices || 0;
    this.registries = data.registries || [{
      registryName: 'docker.io', username: '', password: '', textFieldType: 'text', hidePassword: true,
    }];
    this.remoteConnections = data.version.remoteConnections
      ? data.version.remoteConnections.map((rc) => new RemoteConnectionModel(rc)) : [];
    this.workloadSpecificProperties = {
      dockerConfigurationStorage: data.version.workloadSpecificProperties && data.version.workloadSpecificProperties.dockerConfigurationStorage
        ? data.version.workloadSpecificProperties.dockerConfigurationStorage.map(
          (dockerConfigurationStorage) => new DockerConfigurationStorageModel(dockerConfigurationStorage),
        ) : [],
    };
    this.services = data.version.services ? data.version.services.map((service) => new ComposeServiceModel(service)) : [];
    this.summarizedFileStatuses = data.version.summarizedFileStatuses || {};
    this.isFailed = data.isFailed || false;
    if (this.summarizedFileStatuses.downloading !== 0) {
      this.isDownloading = true;
    }
    if (this.summarizedFileStatuses.failed !== 0) {
      this.errorMessage = 'nerve_workload_file_download_failed';
    }
    if (this.summarizedFileStatuses.cancelled !== 0) {
      this.errorMessage = 'nerve_workload_files_cancelled';
    }
  }
}
