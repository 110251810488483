/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/

import { VersionApiService } from '@/services/api';
import AvailableVersionsModel from '@/model/available-versions/available-versions.model';

export default {
  state: {
    availableVersions: [],
    currentVersion: {},
    incompatibleNodes: [],
  },
  mutations: {
    SET_AVAILABLE_VERSIONS(state, availableVersions) {
      if (availableVersions) {
        state.availableVersions = availableVersions.map(
          (version) => new AvailableVersionsModel(version),
        );
      }
    },
    SET_CURRENT_VERSIONS(state, value) {
      state.currentVersion = value;
    },
    SET_INCOMPATIBLE_NODES(state, value) {
      state.incompatibleNodes = value;
    },
  },
  getters: {
    getAvailableVersions: (state) => () => state.availableVersions,
    getCurrentVersion: (state) => () => state.currentVersion,
    getIncompatibleNodes: (state) => () => state.incompatibleNodes,
  },
  actions: {
    async get_available_versions({ commit }) {
      const response = await VersionApiService.getAvailableVersions();
      commit('SET_AVAILABLE_VERSIONS', response);
    },

    async get_current_versions({ commit }) {
      const response = await VersionApiService.getCurrentVersions();
      commit('SET_CURRENT_VERSIONS', { currentVersion: response.currentVersion });
      commit('SET_AVAILABLE_VERSIONS', response.updates);
    },
    async get_incompatible_nodes({ commit }, version) {
      const response = await VersionApiService.getIncompatibleCompatibleNodesForVersion(version);
      commit('SET_INCOMPATIBLE_NODES', response);
    },
  },
};
