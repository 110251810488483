/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
export default class DockerConfigurationStorageModel {
  /**
    * Creates an instance of DockerConfigurationStorageModel.
    * @param {Object} data - DockerConfigurationStorageModel
    * @class DockerConfigurationStorageModel
    * @property {Array} dockerConfigurationStorage - list of configuration storages
    * defined for each service individually.
    * @property {string} volumeName - name of the volume used as configuration storage
    * @property {string} serviceName - name of the service
    * @property {boolean} restartOnConfigurationUpdate - is restart on configuration update enabled
    */
  constructor(data = {}) {
    this.volumeName = data.volumeName || '';
    this.serviceName = data.serviceName || '';
    this.restartOnConfigurationUpdate = data.restartOnConfigurationUpdate || false;
    this.containerPath = data.containerPath || '';
  }
}
