/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import axios from 'axios';
import store from '@/store';
import { DATA_INTERVAL, WATCHDOG } from '@/constants';
import BaseApiService from './base.api-service';

const PATH = 'nerve/node';
const PATHV2 = 'nerve/v2/node';

const EXCHANGE_PATH = '/nerve/dataExchange/';

const FETCH_NODES_PATH = '/nerve/nodes/filtered/list';

const FETCH_NODES_DEPLOY = '/nerve/nodes/deploy';

const NETWORK_PROPERTIES = 'network-details';

const LOG_LEVEL_LIST = '/nerve/dataExchange/nodeData';

const EXCHANGE_SUFFIXES = {
  START: 'startEmittingUpdates',
  KEEP: 'keepEmittingUpdates',
  CACHED_DATA: 'cachedData',
  NODE_DATA: 'nodeData',
};

class NodesApiService extends BaseApiService {
  createNewNode(data) {
    return this.post(PATH, data, { authRequired: true });
  }

  updateNode(data) {
    return this.patch(`${PATH}`, data, { authRequired: true });
  }

  getNodeById(id, params) {
    const source = axios.CancelToken.source();
    store.dispatch('node-tree/set_cancel_token', source);
    const options = {
      cancelToken: source.token,
      params,
    };
    return this.get(`${PATH}/${id}`, options, { authRequired: true });
  }

  startEmittingUpdates({
    dataId, serialNumber, emitConfig =
    { dataInterval: DATA_INTERVAL, watchdog: WATCHDOG },
  }) {
    const source = axios.CancelToken.source();
    store.dispatch('node-tree/set_cancel_token_start_sending', source);
    const options = {
      cancelToken: source.token,
    };
    return this.post(`${EXCHANGE_PATH}${EXCHANGE_SUFFIXES.START}`, { dataId, emitConfig, serialNumber }, options, { authRequired: true });
  }

  keepEmittingUpdates({
    dataId, serialNumber, emitConfig =
    { dataInterval: DATA_INTERVAL, watchdog: WATCHDOG },
  }) {
    return this.post(`${EXCHANGE_PATH}${EXCHANGE_SUFFIXES.KEEP}`, { dataId, emitConfig, serialNumber }, { authRequired: true });
  }

  getCachedData({ dataId, serialNumber, fromNodeIfCacheEmpty }) {
    const source = axios.CancelToken.source();
    store.dispatch('node-tree/set_cancel_token', source);
    const options = {
      cancelToken: source.token,
    };
    return this.post(`${EXCHANGE_PATH}${EXCHANGE_SUFFIXES.CACHED_DATA}`, { dataId, serialNumber, fromNodeIfCacheEmpty }, options, { authRequired: true });
  }

  getDataFromNode({
    dataId, serialNumber, requestConfig = { forceRequest: true, timeout: 10000 },
    // eslint-disable-next-line no-unused-vars
    callback = null, data,
  }) {
    return this.post(`${EXCHANGE_PATH}${EXCHANGE_SUFFIXES.NODE_DATA}`, {
      dataId, serialNumber, requestConfig, data,
    }, { authRequired: true });
  }

  remove(serialNumber) {
    return this.delete(`${PATH}/${serialNumber}`, { authRequired: true });
  }

  fetchNodes(params) {
    return this.get(FETCH_NODES_PATH, params, { authRequired: true });
  }

  fetchDeployNodes({ workloadId, versionId, params }) {
    return this.get(`${FETCH_NODES_DEPLOY}/${workloadId}/${versionId}`, { params }, { authRequired: true });
  }

  reboot(serialNumber, timeout = 10000) {
    return this.post(`${PATH}/${serialNumber}/reboot`, { timeout }, { authRequired: true });
  }

  applyMonitoringAndLoggingSettings({ serialNumber, data }) {
    return this.post(`${PATH}/applyMonitoringAndLoggingSettings/${serialNumber}`, data, { authRequired: true });
  }

  monitoringAndLoggingSettingsV2({ serialNumber, data }) {
    return this.put(`${PATHV2}/logging-monitoring/${serialNumber}`, data, { authRequired: true });
  }

  getMonitoringAndLoggingSettingsV2(serialNumber) {
    return this.get(`${PATHV2}/logging-monitoring/${serialNumber}`, { authRequired: true });
  }

  getMonitoringAndLoggingSettings(serialNumber) {
    return this.get(`${PATH}/monitoringAndLoggingSettings/${serialNumber}`, { authRequired: true });
  }

  setVideoOutputConfiguration(serialNumber, configs, timeout = 10000) {
    return this.post(`${PATH}/${serialNumber}/videoOutputConfiguration`, { configs, timeout }, { authRequired: true });
  }

  fetchNetworkProperties(serialNumber, deviceId) {
    return this.get(`${PATH}/${serialNumber}/${NETWORK_PROPERTIES}/${deviceId}`, { authRequired: true });
  }

  getLogLevelList(params) {
    return this.post(LOG_LEVEL_LIST, params, { authRequired: true });
  }
}

export default new NodesApiService();
