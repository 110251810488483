/*
*  TTTech nerve-management-system
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/

export default class LabelModel {
  /**
     * Creates an instance of LabelModel.
     * @class LabelModel
     * @property {string} id - Label id
     * @property {string} key - Label key
     * @property {string} value - Label value
     * @property {string} createdAt - Label created timestamp
     */
  constructor({
    _id = '', key = '', value = '', createdAt = '',
  }) {
    this._id = _id;
    this.key = key;
    this.value = value;
    this.createdAt = createdAt;
    this.transformed = this.key && this.value ? `${this.key}:${this.value}` : '';
  }
}
