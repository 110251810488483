/* eslint-disable consistent-return */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
export const RolesHelper = {
  // processedPermissions parameter used to early return if some of permission is already proccessed, not to do it multiple times
  changePermissions(processedPermissions, item, action, allPermissions, uiPermissions, rolePermissions) {
    const selectedPermission = allPermissions.find((permission) => permission._id === item);
    const indexOfPermission = rolePermissions.indexOf(item);
    if (processedPermissions.has(item)) {
      return rolePermissions;
    }
    processedPermissions.add(item);
    if (action === 'uncheck') {
      // Removes permission from role
      rolePermissions.splice(indexOfPermission, 1);
      // Get all permissions granted to role as objects(role just contain permission Ids)
      const grantedPermissions = allPermissions.filter((permission) => rolePermissions.includes(permission._id));
      // Go through selectedPermission requires
      selectedPermission.requires.forEach((permissionRequire) => {
        // If selectedPermission require doesn't belong to UI_PERMISSION category
        if (!(uiPermissions.find((uiPermission) => uiPermission._id === permissionRequire._id))) {
          // Check if some other role granted permissions have this selectedPermission require under its requires permission
          const dontChangePermissions = grantedPermissions.find(({ requires }) => requires.some((require) => require._id === permissionRequire._id));
          // dontChangePermissions  means that some other granted permission requires this selectedPermission require as its requires
          if (!dontChangePermissions) {
            // If only selectedPermission inside grantedPermissions requires this permission
            const indexOfRequire = rolePermissions.indexOf(permissionRequire._id);
            // Remove that required permission from role granted permissions
            rolePermissions.splice(indexOfRequire, 1);
          }
        }
      });
      // Go through all granted permissions
      grantedPermissions.forEach((grantedPermission) => {
        // And if some of them has selectedPermission as requires
        const grantedPermissionRequireIndex = grantedPermission.requires.findIndex((require) => require._id === selectedPermission._id);
        if (grantedPermissionRequireIndex > -1) {
          // This grantedPermission and its requires will be removed from the role under the next iteration of changePermissions recursive function
          rolePermissions = this.changePermissions(processedPermissions, grantedPermission._id, action, allPermissions, uiPermissions, rolePermissions);
        }
      });
      return rolePermissions;
    }
    // Code that add selectedPermission requires and itself to rolePermissions
    // Go through selectedPermission requires
    selectedPermission.requires.forEach((permissionRequire) => {
      if (!rolePermissions.includes(permissionRequire._id)) {
        // If selectedPermission require is not already addded as rolePermission add it to rolePermissions
        rolePermissions.push(permissionRequire._id);
        // And go through its requires to add them to rolePermission also(next iteration of changePermission recursive function)
        rolePermissions = this.changePermissions(processedPermissions, permissionRequire._id, action, allPermissions, uiPermissions, rolePermissions);
      }
    });
    // After all selectedPermission requires added to rolePermissions
    if (!rolePermissions.includes(selectedPermission._id)) {
      // Add permission itself to rolePermission if it is not already added
      rolePermissions.push(selectedPermission._id);
    }
    // Return rolePermissions to calling function so role can be updated inside mutation
    return rolePermissions;
  },

  toggleAllPermissions(allPermissions, uiPermissions, rolePermissions, permissionsToProcess, type) {
    let affectedPermissions = [];
    let action = 'uncheck';
    const allPermissionsChecked = permissionsToProcess.every((permission) => rolePermissions.indexOf(permission._id) > -1);
    if (!allPermissionsChecked) {
      if (type === 'UI') {
        affectedPermissions = permissionsToProcess.filter((permission) => !rolePermissions.includes(permission._id));
      } else {
        affectedPermissions = permissionsToProcess.filter((permission) => uiPermissions.indexOf(permission) === -1 && !rolePermissions.includes(permission._id));
      }
      action = 'check';
    } else {
      if (type === 'UI') {
        affectedPermissions = permissionsToProcess.filter((permission) => rolePermissions.includes(permission._id));
      } else {
        affectedPermissions = permissionsToProcess.filter((permission) => uiPermissions.indexOf(permission) === -1 && rolePermissions.includes(permission._id));
      }
      action = 'uncheck';
    }
    const initialProcessedPermissions = new Set([]);
    affectedPermissions.forEach((affectedPermission) => {
      rolePermissions = this.changePermissions(initialProcessedPermissions, affectedPermission._id, action, allPermissions, uiPermissions, rolePermissions);
    });
    return rolePermissions;
  },
};
