/* eslint-disable no-underscore-dangle */
/*
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/

import NodeModel from '@/model/node/node.model';

export const TREE_NODE_TYPES = {
  ROOT: 'root',
  UNASSIGNED: 'unassigned',
  FOLDER: 'folder',
  NODE: 'node',
};

export default class NodeTreeModel {
  /**
   * Creates an instance of NodeTreeModel.
   * @param {Object} data - NodeTreeModel
   * @class NodeTreeModel
   * @property {string} parentId   - The reference to parent TreeNode
   * @property {string} name       - The name of TreeNode
   * @property {string} type       - The type of TreeNode
   * @property {object} device     - Corresponding device (node)
   * @property {number} orderIndex - Index of the order inside parent
   */
  constructor(data = {}) {
    this.data = data.data || {
      id: data._id,
      parentId: data.parentId || '',
      name: data.name || '',
      type: data.type || '',
      orderIndex: data.orderIndex || 0,
    }; // for the sl-vue-tree

    this.title = this.data.name || data.title || '';
    this.isExpanded = data.isExpanded || false;
    this.data.canBeSelected = this.isTypeOf(TREE_NODE_TYPES.NODE);
    this.isDraggable = !(this.isTypeOf(TREE_NODE_TYPES.UNASSIGNED)
    || this.isTypeOf(TREE_NODE_TYPES.ROOT));
    this.isLeaf = this.isTypeOf(TREE_NODE_TYPES.NODE);

    if (this.isTypeOf(TREE_NODE_TYPES.NODE)) {
      this.isSelected = data.isSelected || false;
      this.data.device = new NodeModel({ ...this.data.device, ...data.device });
    } else {
      this.children = (data.children && data.children.map((n) => new NodeTreeModel(n))) || [];
    }
  }

  isTypeOf(type) {
    return (this.data.type || this.type) === type;
  }

  prepareForApi() {
    return {
      _id: this.data.id, // no fallback for mock endpoint to return generated id
      parentId: this.data.parentId || '',
      name: this.data.name || '',
      type: this.data.type || '',
      device: this.data.device,
      orderIndex: this.isTypeOf(TREE_NODE_TYPES.UNASSIGNED) ? 999 : this.data.orderIndex || 0,
    };
  }
}
