<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <nerve-dialog
    id="iiotProgressBarDialog"
    v-model="dialogState"
    persistent
    scrollable
    max-width="15vw"
    @keydown.esc="close()"
  >
    <v-card
      class="progress-bar">
      <v-card-title id="iiotProgressBarDialogTitle">
          {{progress === 100 ? $t('workloadVersion.progressBarDialog.savingWorkload')
          : $t('workloadVersion.progressBarDialog.uploadingWorkload')}}
        </v-card-title>
      <v-card-text
      >
        <div>
          <v-progress-linear
          v-if="progress<100"
          determinate
          :value="progress"
        />
         <v-progress-linear
          v-if="progress===100"
          indeterminate
        />
        </div>
      </v-card-text>

      <v-card-actions>
        <nerve-button
          id="iiotProgressBarDialogCancelButton"
          :text="$t('dialog.cancel')"
          :class="[{'visibility-hidden': !(progress < 100)}]"
          type-of-btn="close"
          size="normal"
          @click-event="close()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';

export default {
  name: 'ProgressBarDialog',
  components: { NerveButton, NerveDialog },
  computed: {
    progress() {
      return this.$store.getters['workloads/getProgress'];
    },
    dialogState: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getProgressBarDialogState']();
      },
      set() {
        this.close();
      },
    },
    dialogParams() {
      return this.$store.getters['utils/_api_request_handler/getProgressBarDialogParams']();
    },
  },
  methods: {
    async close() {
      await this.$store.dispatch('workloads/cancel_request');
      this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
    },
    async handler() {
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
  .title-text {
      color: #fff;
    }
 .progress-bar {
    height: 220px !important;
  }
</style>
