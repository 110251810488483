<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="22"
    viewBox="0 0 27 22"
  >
    <defs>
      <linearGradient
        id="c"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#00C5EA"
        />
        <stop
          offset="100%"
          stop-color="#0093D0"
        />
      </linearGradient>
    </defs>
    <path
      fill="none"
      fill-rule="evenodd"
      stroke="url(#c)"
      stroke-width="2"
      d="M4 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm17 12a4 4 0 1 1 0-8 4 4 0 0 1 0
      8zM12.5 4v13V4zM8 5.083h4.5H8zm4.5 10.834H17h-4.5z"
      transform="translate(1 1)"
    />
  </svg>
</template>

<script>
export default {
  name: 'TreeFolder',
};
</script>
