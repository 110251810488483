/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import ActiveRemoteConnectionsModel from '@/model/active-remote-connections/active-remote-connections.model';
import ActiveRemoteConnections from '@/services/api/active-remote-connections.api-service';
import ActiveRemoteConnectionsHelper from '@/store/helpers/active-remote-connections.helper';

export default {
  state: {
    remoteList: [],
    remoteConnCount: 0,
    delete: false,
    selectedConnections: [],
  },
  mutations: {
    SET_REMOTE_CONNECTIONS_LIST(state, list) {
      state.remoteList = list.data.map(
        (remoteConn) => new ActiveRemoteConnectionsModel({ ...remoteConn }),
      );
      state.remoteConnCount = list.count;
    },
    TERMINATE_REMOTE_CONNECTIONS(state, toRemove) {
      state.remoteList = state.remoteList.filter(
        (rc) => toRemove.findIndex((conn) => conn.connectionRequestUid === rc.requestUid) === -1,
      );
      state.selectedConnections = [];
    },

    SELECTED_REMOTE_CONNECTION(state, rc) {
      const rcIndex = state.selectedConnections.findIndex(
        (connection) => connection.requestUid === rc.requestUid,
      );
      if (rcIndex !== -1) {
        state.selectedConnections.splice(rcIndex, 1);
        return;
      }
      state.selectedConnections.push(rc);
    },

    SET_SELECTED_REMOTE_CONNECTIONS(state, selectAll) {
      if (!selectAll) {
        state.remoteList.forEach((el) => {
          const rcSelectedIndex = state.selectedConnections.findIndex(
            (rc) => rc.requestUid === el.requestUid,
          );
          state.selectedConnections.splice(rcSelectedIndex, 1);
        });

        return;
      }

      state.remoteList.forEach((el) => {
        if (state.selectedConnections.findIndex((rc) => rc.requestUid === el.requestUid) === -1) {
          state.selectedConnections.push(el);
        }
      });
    },
  },
  getters: {
    list: (state) => state.remoteList,
    count: (state) => state.remoteConnCount,
    getSelectedConnections: (state) => state.selectedConnections,
  },

  actions: {
    async fetch({ state, commit }, data) {
      if (state.delete && state.remoteList.length === 0 && data.page > 1) {
        data.page -= 1;
      }
      let params = {};
      params = {
        type: data.remoteConnectionType === 'ALL' ? '' : data.remoteConnectionType,
        limit: data.itemsPerPage || 10,
        page: data.page || 1,
        order: ActiveRemoteConnectionsHelper.handleOrder(data),
        searchText: data.search || '',
      };
      const list = await ActiveRemoteConnections.fetchRemoteConn({ params });
      commit('SET_REMOTE_CONNECTIONS_LIST', list);
    },

    async terminate_connections({ state, commit }) {
      state.delete = true;
      const toRemove = state.selectedConnections.map(
        (el) => ({
          connectionRequestUid: el.requestUid,
          connectionUid: el.connectionUid,
          connectionName: el.connection,
        }),
      );

      await ActiveRemoteConnections.terminateRemoteConn(toRemove);
      commit('TERMINATE_REMOTE_CONNECTIONS', toRemove);
    },

    async select_connection({ commit }, param) {
      commit('SELECTED_REMOTE_CONNECTION', param);
    },

    async select_all_connections({ commit }, param) {
      commit('SET_SELECTED_REMOTE_CONNECTIONS', param);
    },
  },
};
