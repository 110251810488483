<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <nerve-dialog
    v-model="dialogState"
    scrollable
    max-width="30vw"
    @keydown.enter="confirm()"
  >
    <v-card
      class="deploy-info-dialog"
      id="iiotDeployInfoDialogCard"
    >
      <v-card-title
        class="text-center"
      >
        {{ $t(dialogParams.title) }}
        <br>
        <v-tooltip right max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="mx-2 text-truncate"
              v-on="on"
            >
              {{ dialogParams.serialNumbers }}
            </div>
          </template>
          <span>{{ dialogParams.serialNumbers }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text
        v-if="dialogParams.subTitle"
      >
        {{ $t(dialogParams.subTitle) }}
      </v-card-text>

      <v-card-actions>
        <nerve-button
          id="iiotDeployInfoDialogOkButton"
          :text="$t('dialog.ok')"
          type-of-btn="action"
          size="normal"
          @click-event="confirm()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';

export default {
  name: 'InfoDialog',
  components: { NerveButton, NerveDialog },
  computed: {
    dialogState: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getDeployInfoDialogState'];
      },
      set() {
        this.confirm();
      },
    },
    dialogParams() {
      return this.$store.getters['utils/_api_request_handler/getDeployInfoDialogParams'];
    },
  },
  methods: {
    async confirm() {
      if (this.dialogParams.callback) {
        await this.dialogParams.callback();
      }
      this.$store.dispatch('utils/_api_request_handler/close_deploy_info');
    },
  },
};
</script>
<style lang="scss" scoped>
  .deploy-info-dialog {
    max-height: 460px !important;
    min-height: 360px !important;
  }
</style>
