/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/

export default class AvailableVersionsModel {
  /**
   * * Creates an instance of AvailableVersionsModel.
   * @param {Object} data - AvailableVersionsModel
   * @class AvailableVersionsModel
   * @property {string} _id - id of available update
   * @property {Object} createdBy - timestamp of creation ov available update version
   * @property {boolean} isUpdating - flag if version is updating
   * @property {boolean} isRevertable - flag if version is revertable
   * @property {list} compatible_with - list of versions available version is compatible with
   * @property {list} update_to - versions to witch it can be updated
   * @property {boolean} isReverting - flag if version is reverting
   * @property {string} version - version of available update
   * @property {string} release_date - release date of available update
   * @param data
   */

  constructor(data = {}) {
    // eslint-disable-next-line no-multi-assign
    this.id = data._id;
    this.createdBy = data.createdBy.date || '';
    this.isUpdating = data.isUpdating || '';
    this.isRevertable = data.isRevertable || '';
    this.compatible_with = data.compatible_with || '';
    this.update_to = data.update_to || '';
    this.isReverting = data.isReverting || '';
    this.version = data.version || '';
    this.release_date = new Date(data.release_date).toLocaleDateString('en-GB') || '';
  }
}
