/* eslint-disable consistent-return */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import store from '@/store';
import RolesApiService from '@/services/api/roles.api-service';
import RoleModel from '@/model/roles/role.model';
import PermissionModel from '@/model/roles/permission.model';
import CategoryModel from '@/model/roles/category.model';
import Logger from '@/utils/logger';
import { RolesHelper } from '../helpers/roles.helpers';

export default {
  state: {
    roles: [],
    allRoles: [],
    count: 0,
    role: new RoleModel(),
    allPermissions: [],
    displayedPermissions: [],
    uiPermissions: [],
    displayedUiPermissions: [],
    categories: [],
    dontShowConfirmationDialog: false,
    delete: false,
  },
  mutations: {
    SET_ROLES(state, roles) {
      state.roles = roles.data.map((role) => new RoleModel(role));
      state.count = roles.count;
    },
    SET_ALL_ROLES(state, roles) {
      state.allRoles = roles.map((role) => new RoleModel(role));
      state.count = roles.count;
    },
    SET_ROLE_DATA(state, role) {
      state.role = new RoleModel(role);
    },
    SET_ROLE_PERMISSIONS(state, rolePermissions) {
      state.role.permissions = rolePermissions;
    },
    SET_PERMISSIONS(state, allPermissions) {
      state.allPermissions = allPermissions.map((permission) => new PermissionModel(permission));
    },
    SET_DISPLAYED_PERMISSIONS(state, displayedPermissions) {
      state.displayedPermissions = displayedPermissions.filter((permission) => permission.category.name !== 'UI_PERMISSION').map((permission) => new PermissionModel(permission));
    },
    SET_UI_PERMISSIONS(state, uiPermissions) {
      state.uiPermissions = uiPermissions.map((uiPermission) => new PermissionModel(uiPermission));
    },
    SET_DISPLAYED_UI_PERMISSIONS(state, displayedUiPermissions) {
      state.displayedUiPermissions = displayedUiPermissions.map((uiPermission) => new PermissionModel(uiPermission));
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories.map((category) => new CategoryModel(category));
    },
    SET_DONT_SHOW_DIALOG(state, dontShowConfirmationDialog) {
      state.dontShowConfirmationDialog = dontShowConfirmationDialog;
    },
    REMOVE_ROLE(state, id) {
      state.roles.splice(state.roles.findIndex((role) => role.id === id), 1);
    },
  },
  getters: {
    getRoles: (state) => state.roles,
    getAllRoles: (state) => state.allRoles,
    getRole: (state) => state.role,
    getRolePermissions: (state) => state.role.permissions,
    getAllPermissions: (state) => state.allPermissions,
    getAllUiPermissions: (state) => state.uiPermissions,
    getDisplayedPermissions: (state) => state.displayedPermissions,
    getDisplayedUiPermissions: (state) => state.displayedUiPermissions,
    getCategories: (state) => state.categories,
    getShowDialogStatus: (state) => state.dontShowConfirmationDialog,
    count: (state) => state.count,
  },
  actions: {
    async fetch_roles({ state, commit }, parameters = {}) {
      function handleOrder({ sortBy, sortDesc }) {
        if (!sortBy || !sortDesc) {
          return { _id: 'desc' };
        }

        if (!sortBy.length && !sortDesc.length) {
          return { _id: 'desc' };
        }

        return { [sortBy[0]]: sortDesc[0] ? 'desc' : 'asc' };
      }
      if (state.delete && state.roles.length === 0 && parameters.page > 1) {
        parameters.page -= 1;
      }
      const params = {
        limit: parameters.itemsPerPage || 10,
        page: parameters.page || 1,
        order: handleOrder(parameters),
        filterBy: { name: parameters.search || '', type: parameters.filterBy && parameters.filterBy !== 'All' ? parameters.filterBy : '' },
      };
      const response = await RolesApiService.getAllRoles({ params });
      commit('SET_ROLES', response);
    },
    async fetch_all_roles({ commit }) {
      const params = {};
      const response = await RolesApiService.getAllRoles({ params });
      commit('SET_ALL_ROLES', response);
    },
    async fetch_role({ commit }, roleId) {
      let role = new RoleModel();
      if (roleId && roleId !== '') {
        const response = await RolesApiService.getRole(roleId);
        role = new RoleModel(response);
      }
      commit('SET_ROLE_DATA', role);
    },

    update_role({ commit }, role) {
      commit('SET_ROLE_DATA', role);
    },

    async fetch_permissions({ commit }, filterData) {
      const response = await RolesApiService.getPermissions(filterData);
      if (filterData.categories === 'UI_PERMISSION') {
        commit('SET_UI_PERMISSIONS', response.data);
        commit('SET_DISPLAYED_UI_PERMISSIONS', response.data);
      } else {
        commit('SET_PERMISSIONS', response.data);
        commit('SET_DISPLAYED_PERMISSIONS', response.data);
      }
    },

    async search_permissions({ commit }, filterData) {
      try {
        store.dispatch('utils/_api_request_handler/show_loading_bar');
        filterData.searchText = filterData.search;
        const response = await RolesApiService.getPermissions(filterData);
        if (filterData.categories === 'UI_PERMISSION') {
          commit('SET_DISPLAYED_UI_PERMISSIONS', response.data);
        } else {
          commit('SET_DISPLAYED_PERMISSIONS', response.data);
        }
      } catch (e) {
        Logger.error(e);
      } finally {
        store.dispatch('utils/_api_request_handler/close_loading_bar');
      }
    },

    async fetch_categories({ commit }) {
      const response = await RolesApiService.getCategories();
      commit('SET_CATEGORIES', response);
    },

    toggle_permission({ commit, getters }, permission) {
      const allPermissions = getters.getAllPermissions;
      const uiPermissions = getters.getAllUiPermissions;
      let rolePermissions = getters.getRolePermissions;
      const indexOfPermission = rolePermissions.indexOf(permission._id);
      const selectedUIpermission = uiPermissions.find(
        (UIpermission) => UIpermission._id === permission._id,
      );
      let action = 'check';
      if (indexOfPermission > -1) {
        action = 'uncheck';
      }
      if (!selectedUIpermission && !getters.getShowDialogStatus && action === 'uncheck') {
        store.dispatch('utils/_api_request_handler/show_api_permission_confirm_dialog', {
          title: 'access.roles.roleDetails.apiPermissionsDialogTitle',
          msg: 'access.roles.roleDetails.apiPermissionsDialogMsg',
          confirmCallback: () => {
            rolePermissions = RolesHelper.changePermissions(new Set([]), permission._id, action, allPermissions, uiPermissions, rolePermissions);
            commit('SET_ROLE_PERMISSIONS', rolePermissions);
          },
          cancelCallback: () => {
            commit('SET_ROLE_PERMISSIONS', rolePermissions);
          },
        });
      } else {
        rolePermissions = RolesHelper.changePermissions(new Set([]), permission._id, action, allPermissions, uiPermissions, rolePermissions);
        commit('SET_ROLE_PERMISSIONS', rolePermissions);
      }
    },

    toggle_all_permissions({ commit, getters }) {
      const allPermissions = getters.getAllPermissions;
      const uiPermissions = getters.getAllUiPermissions;
      let rolePermissions = getters.getRolePermissions;
      const apiPermissions = allPermissions.length - uiPermissions.length;
      const permissionsToProcess = getters.getDisplayedPermissions;
      if (rolePermissions.length === apiPermissions) {
        store.dispatch('utils/_api_request_handler/show_api_permission_confirm_dialog', {
          title: 'access.roles.roleDetails.apiPermissionsDialogTitle',
          msg: 'access.roles.roleDetails.apiPermissionsDialogMsg',
          confirmCallback: () => {
            rolePermissions = RolesHelper.toggleAllPermissions(allPermissions, uiPermissions, rolePermissions, permissionsToProcess, 'API');
            commit('SET_ROLE_PERMISSIONS', rolePermissions);
          },
          cancelCallback: () => {
            commit('SET_ROLE_PERMISSIONS', rolePermissions);
          },
        });
      } else {
        rolePermissions = RolesHelper.toggleAllPermissions(allPermissions, uiPermissions, rolePermissions, permissionsToProcess, 'API');
        commit('SET_ROLE_PERMISSIONS', rolePermissions);
      }
    },

    toggle_all_ui_permissions({ commit, getters }) {
      const allPermissions = getters.getAllPermissions;
      const uiPermissions = getters.getAllUiPermissions;
      let rolePermissions = getters.getRolePermissions;
      const permissionsToProcess = getters.getDisplayedUiPermissions;
      rolePermissions = RolesHelper.toggleAllPermissions(allPermissions, uiPermissions, rolePermissions, permissionsToProcess, 'UI');
      commit('SET_ROLE_PERMISSIONS', rolePermissions);
    },

    async save_role({ getters }, roleId) {
      try {
        const payload = { ...getters.getRole };
        if (roleId) {
          payload._id = roleId;
          await RolesApiService.updateRole(payload);
        } else {
          if (payload.type === '') {
            payload.type = 'local';
          }
          await RolesApiService.createRole(payload);
        }
      } catch (err) {
        throw err.response.data[0];
      }
    },

    dont_show_dialog({ commit }, dontShowDialog) {
      commit('SET_DONT_SHOW_DIALOG', dontShowDialog);
    },

    async remove({ state, commit }, { id }) {
      state.delete = true;
      await RolesApiService.deleteRole(id);
      commit('REMOVE_ROLE', id);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'access.roles.list.deletedItem',
        color: 'success',
        showClose: true,
      });
    },
  },
};
