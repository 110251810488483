/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-catch */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import SnapshotModel from '@/model/snapshot/snapshot.model';
import SnapshotScheduleModel from '@/model/snapshot/snapshot-schedule.model';
import { SnapshotApiService } from '@/services/api';

import store from '@/store';

export default {
  state: {
    vmSnapshotList: [],
    scheduleObj: {},
    deviceId: '',
    serialNumber: '',
    isScheduleSaveButtonDisabled: true,
    isScheduleDeleteButtonDisabled: true,
  },
  mutations: {
    SET_SNAPSHOTS(state, snapshots) {
      state.vmSnapshotList = snapshots.map((snapshot) => new SnapshotModel(snapshot));
    },
    SAVE_SNAPSHOT(state, snapshot) {
      state.vmSnapshotList.push(new SnapshotModel(snapshot));
    },
    REMOVE_SNAPSHOT(state, name) {
      state.vmSnapshotList.splice(state.vmSnapshotList
        .findIndex((snapshot) => snapshot.name === name), 1);
    },
    SET_SCHEDULE(state, schedule) {
      state.scheduleObj = new SnapshotScheduleModel(schedule);
    },
    SET_DEVICE_ID(state, id) {
      state.deviceId = id;
    },
    SET_SERIAL_NUMBER(state, serialNumber) {
      state.serialNumber = serialNumber;
    },
    SET_SAVE_BUTTON_FLAG(state, value) {
      state.isScheduleSaveButtonDisabled = value;
    },
    SET_DELETE_BUTTON_FLAG(state, value) {
      state.isScheduleDeleteButtonDisabled = value;
    },
  },
  getters: {
    list: (state) => state.vmSnapshotList,
    schedule: (state) => () => state.scheduleObj,
    isScheduleSaveButtonDisabled: (state) => state.isScheduleSaveButtonDisabled,
    isScheduleDeleteButtonDisabled: (state) => state.isScheduleDeleteButtonDisabled,
    findByName: (state) => (name) => state.vmSnapshotList.find((s) => s.name === name),
  },
  actions: {
    async fetch({ commit }, params) {
      try {
        // If either deviceId or serialNumber does not exist axios request should not be sent
        if (!params.deviceId || !params.serialNumber) {
          return;
        }
        const { snapshots, scheduled } = await SnapshotApiService.fetchSnapshots(params);
        commit('SET_SNAPSHOTS', snapshots);
        commit('SET_SCHEDULE', scheduled);
        if (scheduled && scheduled.type && scheduled.type !== '') {
          commit('SET_DELETE_BUTTON_FLAG', false);
          commit('SET_SAVE_BUTTON_FLAG', true);
          return;
        }
        commit('SET_SAVE_BUTTON_FLAG', true);
        commit('SET_DELETE_BUTTON_FLAG', true);
      } catch (e) {
        commit('SET_SNAPSHOTS', []);
        commit('SET_SCHEDULE', {});
        commit('SET_SAVE_BUTTON_FLAG', true);
        commit('SET_DELETE_BUTTON_FLAG', true);
        throw e;
      }
    },
    async create({ commit }, params) {
      try {
        await SnapshotApiService.create(params);
      } catch (e) {
        throw e;
      }
    },
    async remove({ commit, state }, snapshot) {
      await SnapshotApiService
        .remove({
          deviceId: state.deviceId,
          serialNumber: state.serialNumber,
          snapshotName: snapshot.name,
        });
      commit('REMOVE_SNAPSHOT', snapshot.name);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'vmSnapshot.removeSnapshot',
        color: 'success',
        showClose: true,
      });
    },

    async restore({ commit }, params) {
      try {
        await SnapshotApiService.restore(params);
      } catch (e) {
        throw e;
      }
    },

    async schedule({ commit }, params) {
      try {
        await SnapshotApiService.schedule(params);
        commit('SET_SAVE_BUTTON_FLAG', true);
        commit('SET_DELETE_BUTTON_FLAG', false);
      } catch (e) {
        throw e;
      }
    },

    async remove_schedule({ commit }, params) {
      try {
        await SnapshotApiService.removeSchedule(params);
        commit('SET_SCHEDULE', null);
        commit('SET_SAVE_BUTTON_FLAG', true);
        commit('SET_DELETE_BUTTON_FLAG', true);
      } catch (e) {
        throw e;
      }
    },

    set_device_id({ commit }, deviceId) {
      commit('SET_DEVICE_ID', deviceId);
    },

    set_serial_number({ commit }, serialNumber) {
      commit('SET_SERIAL_NUMBER', serialNumber);
    },

    change_save_button_flag({ commit }, value) {
      commit('SET_SAVE_BUTTON_FLAG', value);
    },

    change_delete_button_flag({ commit }, value) {
      commit('SET_DELETE_BUTTON_FLAG', value);
    },

    show_toast({ commit }, { successMsg, errorMsg, error }) {
      const knownErrors = ['SNAPSHOT_CREATION_FAILED_NO_SPACE_LEFT_ON_DEVICE'];
      // eslint-disable-next-line no-nested-ternary
      const text = !error ? successMsg : (knownErrors.indexOf(error) !== -1 ? `errorMessages.${error}` : errorMsg);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text,
        color: !error ? 'success' : 'red',
        showClose: true,
      });
    },

    mqtt_snapshot({
      commit, getters, state, dispatch,
    }, payload) {
      const { sessionId } = JSON.parse(localStorage.getItem('session'));

      const { params: { data } } = payload;

      // Only the message with the appropriate deviceId is considered
      if (!data || !data.deviceId || parseInt(data.deviceId, 10) !== state.deviceId) {
        return;
      }
      const actionName = payload.params.name;

      // If you are the user who initiated the create/restore snapshot request
      // close loading bar and show the success/error toast
      if (data.sessionId === sessionId) {
        store.dispatch('utils/_api_request_handler/close_loading_bar');
        if (actionName === 'snapshot_created') {
          dispatch('show_toast', { successMsg: 'vmSnapshot.saveSnapshotMessage', errorMsg: 'errorMessages.nerve_workload_047', error: data.error });
        } else if (actionName === 'snapshot_reverted') {
          dispatch('show_toast', { successMsg: 'vmSnapshot.restoreSnapshotMessage', errorMsg: 'errorMessages.nerve_workload_049', error: data.error });
        }
      }

      if (actionName === 'snapshot_created' && !data.error) {
        const { snapshot } = payload.params.data;
        const already = getters.findByName(snapshot.name);
        if (!already) {
          commit('SAVE_SNAPSHOT', snapshot);
        }
      }
      if (actionName === 'snapshot_deleted') {
        commit('REMOVE_SNAPSHOT', payload.params.data.name);
      }
      if (actionName === 'schedule_snapshot_created') {
        commit('SET_SNAPSHOTS', payload.params.data.snapshots);
      }
      if (actionName === 'schedule_config_created') {
        commit('SET_SCHEDULE', payload.params.data.scheduled);
        commit('SET_SAVE_BUTTON_FLAG', true);
        commit('SET_DELETE_BUTTON_FLAG', false);
      }
      if (actionName === 'schedule_config_deleted') {
        commit('SET_SCHEDULE', null);
        commit('SET_SAVE_BUTTON_FLAG', true);
        commit('SET_DELETE_BUTTON_FLAG', true);
      }
    },
  },
};
