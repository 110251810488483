/* eslint-disable max-len */
/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import { NodeUpdateListApiService } from '@/services/api';
import NodeDeviceModel from '@/model/node-update/node-device.model';
import NodeUpdateListModel from '@/model/node-update/node-update-list.model';
import Logger from '@/utils/logger';

export default {
  state: {
    nodeUpdateList: [],
    compatibleDevices: [],
    selectedDevices: [],
    totalCompatibleDevices: 0,
    supportedHardware: [],
    bulkOperation: {},
  },
  mutations: {
    SET_NODE_UPDATE_LIST(state, list) {
      const convertedDataReleaseList = list.map((node) => new NodeUpdateListModel(node));
      state.nodeUpdateList = convertedDataReleaseList.filter((nodeUpdate, i, arr) => arr.findIndex((nu) => nu.name === nodeUpdate.name) === i);
    },
    SET_COMPATIBLE_DEVICES(state, list) {
      state.compatibleDevices = list.map((device) => new NodeDeviceModel(device));
    },
    SELECT_NODE_DEVICE(state, device) {
      const updateNodeListIndex = state.selectedDevices.findIndex((group) => group.id === device.id);
      if (updateNodeListIndex !== -1) {
        state.selectedDevices.splice(updateNodeListIndex, 1);
        return;
      }
      state.selectedDevices.push(device);
    },
    REMOVE_SELECTED_NODE_DEVICE(state, device) {
      state.selectedDevices.splice(state.selectedDevices.findIndex((selectedDevice) => selectedDevice === device.id), 1);
    },
    SELECT_ALL_DEVICES(state) {
      state.compatibleDevices.forEach((compatibleDevice) => {
        if (state.selectedDevices.findIndex((n) => n.id === compatibleDevice.id) === -1) {
          state.selectedDevices.push(compatibleDevice);
        }
      });
    },
    REMOVE_ALL_SELECTED_DEVICES(state) {
      state.compatibleDevices.forEach((compatibleDevice) => {
        const deviceListIndex = state.selectedDevices.findIndex((n) => n.id === compatibleDevice.id);
        state.selectedDevices.splice(deviceListIndex, 1);
      });
    },
    SET_TOTAL_COMPATIBLE_DEVICES(state, count) {
      state.totalCompatibleDevices = count;
    },
  },
  getters: {
    getNodeUpdateList: (state) => state.nodeUpdateList,
    getCompatibleDevices: (state) => state.compatibleDevices,
    getSelectedNodeDevices: (state) => state.selectedDevices,
    getTotalCompatibleDevices: (state) => state.totalCompatibleDevices,
    bulkOperation: (state) => state.bulkOperation,
  },
  actions: {
    async node_update_list({ commit }) {
      const list = await NodeUpdateListApiService.localNodeUpdate();
      commit('SET_NODE_UPDATE_LIST', list);
    },
    async compatible_nodes({ commit, state }, data) {
      let params = {};
      state.supportedHardware = [];
      const nodeUpdateDetails = await NodeUpdateListApiService.detailsByName(data.versionName);
      if (!nodeUpdateDetails || !Array.isArray(nodeUpdateDetails) || (Array.isArray(nodeUpdateDetails) && nodeUpdateDetails.length === 0)) {
        Logger.warn(`No update details for the version ${data.versionName} is available.`);
        return;
      }
      // eslint-disable-next-line array-callback-return
      nodeUpdateDetails.map((image) => {
        if (!state.supportedHardware.includes(image.hardwareModel)) {
          state.supportedHardware.push(image.hardwareModel);
        }
      });
      params = {
        filterBy: {
          compatibleVersions: nodeUpdateDetails[0].updateFrom,
          connectionStatus: 'online',
          hardwareModel: state.supportedHardware,
          ...(data.search && data.search !== '' && { name: data.search }),
        },
        limit: data.itemsPerPage || 10,
        page: data.page || 1,
      };

      const compatibleDevices = await NodeUpdateListApiService.compatibleDevices({ params });
      commit('SET_COMPATIBLE_DEVICES', compatibleDevices.devices);
      commit('SET_TOTAL_COMPATIBLE_DEVICES', compatibleDevices.count);
    },
    select_device({ commit }, device) {
      commit('SELECT_NODE_DEVICE', device);
    },
    remove_selected_node_device({ commit }, device) {
      commit('REMOVE_SELECTED_NODE_DEVICE', device);
    },
    async update_node_to_new_version({ state }, versionName) {
      const nodeUpdateDetails = await NodeUpdateListApiService.detailsByName(versionName);
      const serialNumberList = state.selectedDevices.map((device) => device.serialNumber);
      await NodeUpdateListApiService.update(serialNumberList, nodeUpdateDetails);
    },
    select_all_devices({ commit }) {
      commit('SELECT_ALL_DEVICES');
    },
    remove_all_selected_devices({ commit }) {
      commit('REMOVE_ALL_SELECTED_DEVICES');
    },
  },
};
