/*
* TTTech nerve-management-system
* Copyright(c) 2022. TTTech Industrial Automation AG.
*
* ALL RIGHTS RESERVED.
*
* Usage of this software, including source code, netlists, documentation,
* is subject to restrictions and conditions of the applicable license
* agreement with TTTech Industrial Automation AG or its affiliates.
*
* All trademarks used are the property of their respective owners.
*
* TTTech Industrial Automation AG and its affiliates do not assume any liability
* arising out of the application or use of any product described or shown
* herein. TTTech Industrial Automation AG and its affiliates reserve the right to
* make changes, at any time, in order to improve reliability, function or
* design.
*
* Contact Information:
* support@tttech-industrial.com
*
* TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import { WorkloadDetailsApiService } from '@/services/api';
import store from '@/store';

export default {
  state: {},
  mutations: {},
  getter: {},
  actions: {
    async update_workload(_, data) {
      store.dispatch('utils/_api_request_handler/show_progress_bar_dialog');
      await WorkloadDetailsApiService.addUpdateWorkload(data);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'workloadDetail.successfullyUpdate',
        color: 'success',
        showClose: true,
      });
    },
  },

};
