/*
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
export default class ConnectionTunnelModel {
  /**
     * Creates an instance of Connection tunnel Model.
     * @param {Array} data
     * @class ConnectionScreenModel
     * @property {string} id - Connection id
     * @property {string} name - Connection name
     * @property {string} acknowledgment - Local acknowledgment
     * @property {String} hostname - hostname
     * @property {number} port - port on node
     * @property {number} localPort - local port
     * @property {string} type - type
     */
  constructor(data = {}) {
    // eslint-disable-next-line no-multi-assign
    this.id = this._id = data._id;
    this.name = data.name || '';
    this.acknowledgment = data.acknowledgment || 'No';
    this.hostname = data.hostname || '172.20.3.2';
    this.port = data.port || '11740';
    this.localPort = data.localPort || '';
    this.type = data.type || '';
    this.serviceName = data.serviceName || '';
  }
}
