/* eslint-disable class-methods-use-this */
/* eslint-disable no-nested-ternary */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021 TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

class DeployLogHelper {
  /**
     * Method that will prepare filterBy param before sending
     * request to the api
     * @param params
     * @returns {{operation_name: (*|string), contentSubtype: (string|string), dryRun: (null|*)}}
     */
  handleFilterBy(params) {
    const contentSubtype = params.selectedWorkloadType === 'ALL' ? '' : params.selectedWorkloadType ? params.selectedWorkloadType.toLowerCase() : params.selectedWorkloadType;
    const dryRun = params.selectedDeploymentType === 'ALL' || params.selectedDeploymentType === '' ? '' : params.selectedDeploymentType === 'true';
    return {
      operation_name: params.search || '',
      contentSubtype,
      dryRun,
    };
  }
}

export default new DeployLogHelper();
