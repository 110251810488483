<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="90"
    viewBox="0 0 90 90"
  >
    <g
      fill="none"
      fill-rule="nonzero"
    >
      <path
        fill="#FFF"
        d="M45.4 17.174l36.497 15.91a1 1 0 0 1 0 1.833L45.4 50.826a1
        1 0 0 1-.8 0L8.103 34.916a1 1 0 0 1 0-1.833L44.6 17.174a1 1 0 0 1 .8 0z"
      />
      <path
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
        d="M83 57.5L45 74 7 57.5"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VmIcon',
};
</script>
