<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <nerve-dialog
    id="iiotErrorInfoDialog"
    v-model="dialogState"
    scrollable
    persistent
    max-width="30vw"
    @keydown.esc="close()"
    @keydown.enter="close()"
  >
    <v-card
      id="iiotErrorInfoDialogCard"
      class="error-dialog"
    >
      <v-card-title
        id="iiotErrorInfoDialogCardTitle">
        {{ $t(dialogParams.title) }}
      </v-card-title>
      <v-card-text id="iiotErrorInfoDialogCardText">
        <div v-html="dialogParams.message"/>
      </v-card-text>
      <v-card-actions>
        <nerve-button
          id="iiotErrorInfoDialogCloseButton"
          :text="$t('dialog.close')"
          type-of-btn="close"
          size="normal"
          @click-event="close()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';

export default {
  name: 'ErrorInfoDialog',
  components: { NerveButton, NerveDialog },
  computed: {
    dialogState: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getErrorInfoDialogState']();
      },
    },
    dialogParams() {
      const getErrorInfo = this.$store.getters['utils/_api_request_handler/getErrorInfoDialogParams']();
      // eslint-disable-next-line no-useless-concat
      const errorMessage = getErrorInfo.timeOfError ? (`<b>${ this.$t('errorInfoDialog.message') }</b>${ getErrorInfo.defautlMsg }<br/>` + `<b>${ this.$t('errorInfoDialog.troubleshooting') }</b>${ getErrorInfo.troubleshooting }<br/>` + `<b>${ this.$t('errorInfoDialog.timeOfError') }</b>${ getErrorInfo.timeOfError}`) : (`<b>${ this.$t('errorInfoDialog.message') }</b>${ getErrorInfo.defautlMsg }<br/>` + `<b>${ this.$t('errorInfoDialog.troubleshooting') }</b>${ getErrorInfo.troubleshooting}`);
      return { message: errorMessage, title: getErrorInfo.title };
    },
  },
  methods: {
    close() {
      this.$store.dispatch('utils/_api_request_handler/close_error_info_dialog');
    },
  },
};
</script>
<style lang="scss" scoped>
  .error-dialog {
    max-height: 460px !important;
    min-height: 320px !important;
  }
</style>
