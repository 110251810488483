/*
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/

export default class GroupLabelModel {
  /**
     * Creates an instance of LabelModel.
     * @class GroupLabelModel
     * @property {string} id - Group label id
     * @property {string} key - Group label key
     * @property {number} count - Count of labels with group label key
     * @property {Array} keys - All keys with key value
     * @property {Array} labels - All labels with key value
     */
  constructor({
    _id = '', key = '', count = 0, keys = [], labels = [],
  }) {
    this.id = _id;
    this.key = key;
    this.count = count;
    this.keys = keys;
    this.labels = labels;
  }
}
