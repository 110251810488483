/* eslint-disable max-len */
/*
*  TTTech nerve-management-system
*  Copyright(c) 2019. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
export default class GroupsModel {
  /**
   * Creates an instance of GroupsModel.
   * @param {Array} data - GroupsModel list
   * @class GroupsModel
   * @property {string} searchBase - The directory point where to start performing the synchronization
   * @property {string} filter - Filter
   * @property {string} name - LDAP attribute that holds groups name. Usually it's cn
   * @property {string} adminGroup - Name of the LDAP admin group
   * @property {string} default - Default role to map new groups to
   */
  constructor(data = {}) {
    this.searchBase = data.searchBase || '';
    this.filter = data.filter || '';
    this.name = data.name || '';
    this.adminGroup = data.adminGroup || '';
    this.default = data.default || '';
  }
}
