/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import DeployLogList from '@/services/api/deploy.log.api-service';
import DeployLogModel from '@/model/deploy-log/deploy-log.model';
import DeployLogHelper from '@/store/helpers/deploy-log.helper';
import store from '@/store';

export default {
  state: {
    deployedList: [],
    count: 0,
    delete: false,
  },
  mutations: {
    SET_LIST(state, deployed) {
      state.deployedList = deployed.data.map((i) => new DeployLogModel(i));
      state.count = deployed.count;
    },
    REMOVE_DEPLOY(state, dep) {
      state.deployedList.splice(state.deployedList.findIndex((deployed) => deployed.id === dep.id), 1);
    },
    UPDATE_LOG(state, payload) {
      state.deployedList.map((log) => {
        if (log.id === payload.id) {
          log.progress = payload.progress.toFixed(2);
          log.status = payload.status;
          log.started = new Date(payload.timeOfOperationStart).toLocaleString('en-GB');
        }
      });
    },
    FINISHED_LOG(state, payload) {
      state.deployedList.map((log) => {
        if (log.id === payload.id) {
          log.progress = payload.progress.toFixed(2);
          log.status = payload.status;
          log.finished = new Date(payload.timeOfOperationFinish).toLocaleString('en-GB');
        }
      });
    },
  },
  getters: {
    list: (state) => state.deployedList,
    count: (state) => state.count,
  },
  actions: {
    async fetch({ state, commit }, data) {
      if (state.delete && state.deployedList.length === 0) {
        if (data.page > 1) {
          data.page -= 1;
        }
      }
      const params = {
        filterBy: DeployLogHelper.handleFilterBy(data),
        limit: data.itemsPerPage || 10,
        page: data.page || 1,
        contentType: 'workload',
      };
      commit('SET_LIST', await DeployLogList.fetchDeploy({ params }));
    },
    async delete_deploy({ state, commit }, param) {
      state.delete = true;
      await DeployLogList.deleteDeploy(param);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'deployLog.list.successfullyDeleted',
        color: 'success',
        showClose: true,
      });
      commit('REMOVE_DEPLOY', param);
    },
    updated_campaign({ commit }, payload) {
      commit('UPDATE_LOG', payload.params);
    },
    updated_finished_campaign({ commit }, payload) {
      commit('FINISHED_LOG', payload.params.data);
    },
  },
};
