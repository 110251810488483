/*
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import { HardwareApiService } from '@/services/api';
import HardwareModel from '@/model/hardware/hardware.model';

export default {
  state: {
    hardware: [],
  },
  mutations: {
    SET_HARDWARE(state, list) {
      list = list.filter((el) => el.name !== 'nerve');
      state.hardware = list.map((hardware) => new HardwareModel(hardware));
    },
  },
  getters: {
    getHardwareList: (state) => state.hardware,
  },
  actions: {
    async init_hardware_list({ commit }) {
      const hwList = await HardwareApiService.getHardwareList();
      commit('SET_HARDWARE', hwList.data);
    },
  },
};
