import AuthModel from '@/model/auth/auth.model';
import mqtt from '@/plugins/mqtt';
import { AuthApiService, PermissionApiService } from '@/services/api';

export default {
  state: {
    user: {},
    userPermissions: {},
    isServiceUnavailable: {},
  },
  mutations: {
    SET_AUTH_USER(state, value) {
      state.user = new AuthModel(value);
    },
    CHANGE_USER_PERMISSIONS_STATE(state, value) {
      state.userPermissions = value;
    },
    SET_SERVICE(state, value) {
      state.isServiceUnavailable = value;
    },
  },
  getters: {
    isLoggedIn: (state) => () => !!state.user.username,
    getUserPermissions: (state) => state.userPermissions,
    authUser: (state) => state.user,
    isServiceUnavailable: (state) => state.isServiceUnavailable,
  },
  actions: {
    async login({ commit }, authData) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await AuthApiService.login(authData);
        commit('CHANGE_USER_PERMISSIONS_STATE', response.user.permissions);
        commit('SET_AUTH_USER', response.user);
        localStorage.setItem('userDetails', JSON.stringify(response.user));
        localStorage.setItem('session', JSON.stringify({ userId: response.user.id, sessionId: response.user.sessionId }));
        // all cookies are set on server side.
      } catch (e) {
        throw e;
      }
    },

    async is_user_logged({ commit, dispatch }) {
      try {
        const loggedUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = loggedUser ? loggedUser.id : '';
        if (userId) {
          commit('SET_AUTH_USER', loggedUser);
          const response = await PermissionApiService.getPermissionByUserId(userId);
          commit('CHANGE_USER_PERMISSIONS_STATE', response);
          return;
        }
        await dispatch('clearLocalStorage');
      } catch (e) {
        await dispatch('clearLocalStorage');
        throw e;
      }
    },

    async logout({ commit }) {
      commit('SET_AUTH_USER', {});
      await AuthApiService.logout();
      await mqtt.disconnect();
    },

    async is_services_unavailable({ commit }, obj) {
      commit('SET_SERVICE', obj);
    },

    clearLocalStorage() {
      localStorage.removeItem('userDetails');
      localStorage.removeItem('session');
    },
    update_user({ commit }, user) {
      commit('SET_AUTH_USER', user);
    },
  },
};
