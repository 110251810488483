/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
import shared from '@/helpers/shared';

export default class UsageReportModel {
  /**
   * * Creates an instance of UsageReportModel.
   * @param {Object} data - UsageReportModel
   * @class UsageReportModel
   * @property {Sring} id - id of usage report
   * @property {string} name - usage report name
   * @property {string} relativePath - path to usage report
   * @property {string} month - month of the report
   * @property {string} year - year of the report
   * @property {date} dateOfReport - timestamp of the report
   * @property {boolean} isComplete - flag if the report is complete
   * @param data
   */
  constructor(data = {}) {
    // eslint-disable-next-line no-multi-assign
    this.id = data._id;
    this.name = data.name || '';
    this.relativePath = data.relativePath || '';
    this.month = data.month || '';
    this.year = data.year || '';
    this.dateOfReport = data.dateOfReport ? shared.dateFormatByLanguage(data.dateOfReport) : '';
    this.isComplete = data.isComplete || '';
  }
}
