/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import axios from 'axios';
import store from '@/store';
import BaseApiService from './base.api-service';

const PATH = 'nerve/workload';
const PATHV2 = 'nerve/v2/workloads';
const PATHS = {
  DELETE: 'nerve/workload',
  CANCEL: 'nerve/workload',
  DISABLE_ENABLE: 'nerve/workload/disable',
  DEPLOY: 'bom/nerve/workload/deploy',
  UPDATE_RESOURCES: 'nerve/workload/updateResources',
  DEPLOYED_WORKLOAD: 'nerve/workload/deployedWorkload',
  CHECK_FILE: 'nerve/workload/check',
};
class WorkloadsApiService extends BaseApiService {
  createNewWorkloadVersion(param) {
    const options = {
      headers: { 'Content-Type': undefined },
      onUploadProgress: (e) => {
        const progressPercentage = (e.loaded / e.total) * 100;
        store.dispatch('workloads/set_progress', progressPercentage);
      },
      cancelToken: param.token,
    };
    return this.post(`${PATHV2}`, param.payload, options, { authRequired: true });
  }

  updateVersion(param) {
    const options = {
      headers: { 'Content-Type': undefined },
      onUploadProgress: (e) => {
        const progressPercentage = (e.loaded / e.total) * 100;
        store.dispatch('workloads/set_progress', progressPercentage);
      },
      cancelToken: param.token,
    };
    return this.patch(`${PATHV2}`, param.payload, options, { authRequired: true });
  }

  getAllDeployedWorkloads(serialNumber) {
    const source = axios.CancelToken.source();
    store.dispatch('node-tree/set_cancel_token', source);
    const options = {
      cancelToken: source.token,
    };
    return this.get(`${PATH}/node/${serialNumber}/devices`, options, { authRequired: true });
  }

  setCommand(data) {
    return this.post(`${PATH}/controller`, data, { authRequired: true });
  }

  fetchWorkloads(params) {
    return this.get(PATHV2, params, { authRequired: true });
  }

  deleteWorkload(param) {
    return this.delete(`${PATHS.DELETE}/${param._id}`, { authRequired: true });
  }

  disableEnableWorkload(param) {
    return this.patch(`${PATHS.DISABLE_ENABLE}/${param._id}`, param, { authRequired: true });
  }

  getWlById(id) {
    return this.get(`${PATHV2}/${id}`, { authRequired: true });
  }

  deploy(param) {
    return this.post(PATHS.DEPLOY, param, { authRequired: true });
  }

  updateResources(data) {
    return this.post(PATHS.UPDATE_RESOURCES, data, { authRequired: true });
  }

  getWorkloadVersions(id) {
    return this.get(`${PATHS.DEPLOYED_WORKLOAD}/${id}`, { authRequired: true });
  }

  deleteVersion(param) {
    return this.delete(`${PATHV2}/${param.wlId}/versions/${param.verId}`, { authRequired: true });
  }

  canceledVersionCreation(param) {
    return this.post(`${PATHS.CANCEL}/${param.wlId}/version/${param.verId}`, {}, { authRequired: true });
  }

  getWorkloadVersionRc(param) {
    return this.get(`${PATH}/${param.workloadId}/version/${param.versionId}/remoteConnections`, { authRequired: true });
  }

  updateWorkload(id) {
    return this.get(`${PATH}/${id}`, { authRequired: true });
  }

  checkFiles(param) {
    return this.get(`${PATHS.CHECK_FILE}/${param.wlId}/${param.verId}`);
  }

  getUniqueInfo(params) {
    return this.get(`${PATHV2}/${params.workloadId}/versions/${params.versionId}/unique-info`, { authRequired: true });
  }
}

export default new WorkloadsApiService();
