export default [

  {
    path: '/login',
    name: 'Login',
    view: 'Login',
  },
  {
    path: '/nodes',
    view: 'Nodes',
    name: 'Nodes',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
      sortBy: route.query.sortBy,
      sortDesc: route.query.sortDesc,
    }),
  },
  {
    path: '/labels',
    view: 'Labels',
    name: 'Labels',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
      sortBy: route.query.sortBy,
      sortDesc: route.query.sortDesc,
    }),
  },
  {
    path: '/labels/merge',
    view: 'Merge',
    name: 'Merge',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
    }),
  },
  {
    path: '/labels/:id',
    view: 'AddEditLabel',
    name: 'Add Edit Label',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Nodes', path: '/nodes' },
    view: 'Nodes',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    name: 'Home',
    redirect: { name: 'Nodes', path: '/nodes' },
    view: 'Nodes',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/notifications',
    view: 'Notifications',
    name: 'Notifications',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
    }),
  },
  {
    path: '/notifications/:id',
    view: 'AddEditNotification',
    name: 'Add edit notification',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nodes/:id',
    view: 'AddEditNode',
    name: 'Add edit node',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/remotes',
    view: 'ActiveRemoteConnections',
    name: 'Active Remote Connections',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
      sortBy: route.query.sortBy,
      sortDesc: route.query.sortDesc,
      type: route.query.type,
    }),
  },
  {
    path: '/system-info',
    view: 'SystemInfo',
    name: 'System info',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      search: route.query.search,
    }),
  },
  {
    path: '/users',
    view: 'Users',
    name: 'Users',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
      sortBy: route.query.sortBy,
      sortDesc: route.query.sortDesc,
      type: route.query.type,
    }),
  },
  {
    path: '/users/:id',
    view: 'AddEditUser',
    name: 'Add Edit User',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user_settings',
    view: 'AddEditUser',
    name: 'User Settings',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/device/:currentGtwId/:id',
    view: 'DeployedWorkloadControl',
    name: 'Deployed workload control',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/roles',
    view: 'Roles',
    name: 'Roles',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
      type: route.query.type,
      sortBy: route.query.sortBy,
      sortDesc: route.query.sortDesc,
    }),
  },
  {
    path: '/roles/:id',
    view: 'RoleDetails',
    name: 'Role Details',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ldap',
    view: 'Ldap',
    name: 'Ldap',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/node/update/list',
    view: 'NodeUpdateList',
    name: 'Node Update List',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/node/update/log',
    view: 'NodeUpdateLog',
    name: 'Node Update Log',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
    }),
  },
  {
    path: '/node/update/log/:id',
    view: 'NodeUpdateDetails',
    name: 'Node Update Details',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
      isFinished: route.query.isFinished,
      isCanceled: route.query.isCanceled,
      inProgress: route.query.inProgress,
      isFailed: route.query.isFailed,
    }),
  },
  {
    path: '/workloads',
    view: 'Workloads',
    name: 'Workloads',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
      sortBy: route.query.sortBy,
      sortDesc: route.query.sortDesc,
      type: route.query.type,
      disabled: route.query.disabled,
    }),
  },
  {
    path: '/workloads/:id/:type',
    view: 'AddEditWorkload',
    name: 'Add edit workload',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/workloads/:workloadId/versions/:versionId/:type',
    view: 'AddEditWorkloadVersion',
    name: 'Add edit workload version',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/forgot-password',
    view: 'ForgotPassword',
    name: 'Forgot Password',
  },
  {
    path: '/resetPassword/:hash',
    view: 'ResetPassword',
    name: 'Reset Password',
  },
  {
    path: '/activate/:activationToken',
    view: 'ActivateProfile',
    name: 'Activate Profile',
  },
  {
    path: '/deploy/workload',
    view: 'DeployForm',
    name: 'Deploy Form',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/deploy/workload/:type/:workloadId/:versionId/:origin',
    view: 'DeployForm',
    name: 'Deploy Form Step Two',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/deploy/dryRun',
    view: 'DeployForm',
    name: 'Deploy Form Dry Run',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/deploy/dryRun/workload/:type/:workloadId/:versionId/:origin',
    view: 'DeployForm',
    name: 'Deploy Form Step Two Dry Run',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/deploy/log',
    view: 'DeployLogList',
    name: 'Deploy Log List',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
      deploymentType: route.query.deploymentType,
      workloadType: route.query.workloadType,
    }),
  },
  {
    path: '/deploy/details/:id',
    view: 'DeployDetails',
    name: 'Deploy Details',
    meta: {
      requiresAuth: true,
    },
    props: (route) => ({
      page: route.query.page,
      itemsPerPage: route.query.itemsPerPage,
      search: route.query.search,
      isFinished: route.query.isFinished,
      isCanceled: route.query.isCanceled,
      inProgress: route.query.inProgress,
      isFailed: route.query.isFailed,
    }),
  },
  {
    path: '/gClient/:authToken/:identifier',
    view: 'GuacamoleClient',
    name: 'Guacamole Client',
    meta: {
      requiresAuth: true,
    },
  },

];
