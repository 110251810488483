<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="22"
    viewBox="0 0 27 22"
  >
    <path
      fill="none"
      fill-rule="evenodd"
      stroke="#0C283A"
      stroke-width="2"
      d="M5 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm17 12a4 4 0 1 1 0-8 4 4 0 0 1 0
      8zM13.5 5v13V5zM9 6.083h4.5H9zm4.5 10.834H18h-4.5z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TreeFolder',
};
</script>
