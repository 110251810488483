/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import { NodesApiService } from '@/services/api';
import { VALIDATION_REGEX } from '@/constants';
import shared from '../../helpers/shared';
import Logger from '@/utils/logger';

class WorkloadsHelper {
  /**
   * Method that will prepare order parameter
   * before sending request to fetch list of workloads
   * @param sortBy
   * @param sortDesc
   * @returns {{}|{created: string}}
   */
  // eslint-disable-next-line class-methods-use-this
  handleOrder({ sortBy, sortDesc }) {
    if (!sortBy || !sortDesc) {
      return {};
    }

    if (!sortBy.length && !sortDesc.length) {
      return { created: 'desc' };
    }

    return { [sortBy[0]]: sortDesc[0] ? 'desc' : 'asc' };
  }

  /**
   * Method that will prepare filterBy param before sending
   * request to the api
   * @param params
   * @returns {{name: (*|string), disabled: (null|*), type: (string|string)}}
   */
  // eslint-disable-next-line class-methods-use-this
  handleFilterBy(params) {
    // eslint-disable-next-line no-nested-ternary
    const type = params.selectedType === 'ALL' ? '' : params.selectedType ? params.selectedType.toLowerCase() : params.selectedType;
    return {
      name: params.search || '',
      disabled: params.disabled ? undefined : params.disabled,
      type,
    };
  }

  // field used to place exchange interval timer instance
  exchangeInterval;

  // exchange interval length
  EXCHANGE_INTERVAL_LENGTH = 10000;

  // field used to set selected workload
  selectedWorkload;

  // field used to set selected tab for selected workload
  selectedWorkloadTab;

  // field used to set selected node
  selectedNode;

  // flag for checking if startEmittingUpdates event triggered properly
  isTriggerStartEmitting = false;

  /**
   * @description setting timer to command node to continue
   * triggering on every 10 seconds
   * @type {function(): (*)}
   */
  async invokeMqttEvents() {
    if (['removing', 'removing_failed'].includes(this.selectedWorkload.currentStatus)) {
      // if workload is in removing or removing failed status we are not getting any data from node
      this.clearExchangeInterval();
      return;
    }
    const isVersionSatisfied = shared.isNoPrerelesedVersionGreaterThanOrEqualTo(this.selectedNode.currentFWVersion, '2.7.0');
    if (!this.selectedWorkloadTab || !isVersionSatisfied) {
      NodesApiService.keepEmittingUpdates({
        dataId: `${this.selectedWorkload.workloadId}_${this.selectedWorkload.versionId}_stats`,
        serialNumber: this.selectedNode.serialNumber,
      }).catch((e) => {
        Logger.error('NodesApiService.keepEmittingUpdates ', e.message);
        this.clearExchangeInterval();
      });
    }
    if (this.selectedWorkload._type === 'docker-compose' && this.selectedWorkloadTab === 1 && isVersionSatisfied) {
      this.startEmitting();
    }

    this.selectedWorkload.exchangeInterval = setInterval(() => {
      if (!this.selectedNode || !this.selectedNode.isOnline() || !this.selectedWorkload
      || (!this.isTriggerStartEmitting && this.selectedWorkloadTab === 1)) {
        this.clearExchangeInterval();
        return;
      }
      if (!this.selectedWorkloadTab || !isVersionSatisfied) {
        NodesApiService.keepEmittingUpdates({
          dataId: `${this.selectedWorkload.workloadId}_${this.selectedWorkload.versionId}_stats`,
          serialNumber: this.selectedNode.serialNumber,
        }).catch((e) => {
          Logger.error('NodesApiService.keepEmittingUpdates ', e.message);
          this.clearExchangeInterval();
        });
      }
      if (this.selectedWorkload._type === 'docker-compose' && this.selectedWorkloadTab === 1 && isVersionSatisfied) {
        NodesApiService.keepEmittingUpdates({
          dataId: `${this.selectedWorkload.workloadId}_${this.selectedWorkload.versionId}_all_services_status`,
          serialNumber: this.selectedNode.serialNumber,
        }).catch((e) => {
          Logger.error('NodesApiService.keepEmittingUpdates ', e.message);
          this.clearExchangeInterval();
        });
      }
    }, this.EXCHANGE_INTERVAL_LENGTH);
  }

  /**
   * @description sending request that will trigger node to start sending mqtt events
   * @type {function(): (*)}
   */
  // eslint-disable-next-line class-methods-use-this
  async startEmitting() {
    try {
      if (!this.selectedNode.isOnline()) {
        return;
      }
      await NodesApiService.startEmittingUpdates({
        dataId: `${this.selectedWorkload.workloadId}_${this.selectedWorkload.versionId}_all_services_status`,
        serialNumber: this.selectedNode.serialNumber,
      });
      this.isTriggerStartEmitting = true;
    } catch (e) {
      this.isTriggerStartEmitting = false;
      Logger.error('NodesApiService.startEmittingUpdates ', e.message);
    }
  }

  /**
   * @description Set selected node from tree
   * @type {function(): (*)}
   */
  setSelectedNode(node) {
    this.selectedNode = node;
  }

  /**
   * @description Set selected workload from node
   * @type {function(): (*)}
   */
  setSelectedWorkload(workload) {
    this.selectedWorkload = workload;
  }

  /**
   * @description Set selected tab of the selected workload from node
   * @type {function(): (*)}
   */
  setSelectedWorkloadTab(tab) {
    this.selectedWorkloadTab = tab;
  }

  /**
   * @description Set StartEmitting flag
   * @type {function(): (*)}
   */
  setStartEmittingFlag(value) {
    this.isTriggerStartEmitting = value;
  }

  /**
 * @description Clear exchange interval timer
 */
  clearExchangeInterval() {
    if (this.selectedWorkload && this.selectedWorkload.exchangeInterval) {
      // eslint-disable-next-line max-len
      this.selectedWorkload.exchangeInterval = clearInterval(this.selectedWorkload.exchangeInterval);
    }
  }

  /**
   * Returns a list of 'named' volumes
   *
   * @param {Array} volumes A list of service volumes
   */
  // eslint-disable-next-line class-methods-use-this
  findNamedVolumes(volumes) {
    if (!volumes || !Array.isArray(volumes) || !volumes.length) {
      return [];
    }

    // check and return named volume, otherwise null
    const getNamedVolume = (volume) => {
      if (!volume) {
        return null;
      }
      // long syntax volume
      if (typeof volume !== 'string') {
        const { type, source, target } = volume;

        const isSourceValid = source && VALIDATION_REGEX.DOCKER_VOLUME_NAME.test(source);
        const isTargetValid = target && VALIDATION_REGEX.DOCKER_VOLUME_PATH.test(target);

        return (type === 'volume' && isSourceValid && isTargetValid) ? { volumeName: source, containerPath: target } : null;
      }
      // short syntax volume => volume:container_path:access_mode
      const splitByColon = volume.split(':');

      // container path
      if (splitByColon.length === 1) {
        return null;
      }

      const isBindMount = splitByColon[0].includes('/');
      if (isBindMount) {
        return null;
      }

      if (!VALIDATION_REGEX.DOCKER_VOLUME_NAME.test(splitByColon[0])) {
        return null;
      }

      // check the containerPath by regex
      if (!VALIDATION_REGEX.DOCKER_VOLUME_PATH.test(splitByColon[1])) {
        return null;
      }
      return { volumeName: splitByColon[0], containerPath: splitByColon[1] };
    };

    return volumes.reduce((arr, volume) => {
      const namedVolume = getNamedVolume(volume);
      if (namedVolume) {
        arr.push(namedVolume);
      }
      return arr;
    }, []);
  }

  /**
   * Parse list of environment variables
   *
   * @param {Array} envVars A list of environment variables
   */
  // eslint-disable-next-line class-methods-use-this
  parseEnvVars(envVars) {
    return envVars ? envVars.map((element) => {
      if (typeof element === 'string') {
        element = { variable: element.split('=')[0], value: element.split('=')[1] };
      }
      return element;
    }) : [];
  }

  /**
   * Parse list of ports
   *
   * @param {Array} ports A list of ports
   */
  // eslint-disable-next-line class-methods-use-this
  parsePorts(ports) {
    return ports ? ports.map((element) => {
      element.protocol = element.protocol.toUpperCase();
      return element;
    }) : [];
  }
}

export default new WorkloadsHelper();
