/*
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
/* eslint-disable space-before-function-paren */
/* eslint-disable no-underscore-dangle */
export default class CategoryModel {
  /**
     * Creates an instance of CategoryModel.
     * @param {Object} category - Category object from the API
     * @class CategoryModel
     * @property {string} _id - Category id
     * @property {string} name - Category name
     * @property {boolean} display - Display category
     */

  constructor (category = {}) {
    this._id = category._id || '';
    this.name = category.name || '';
    this.display = category.display || false;
  }
}
