/*
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
export default class HardwareModel {
  /**
   * Creates an instance of HardwareModel.
   * @param {Object} data - HardwareModel
   * @class HardwareModel
   * @property {string} name - Hardware name
   * @property {string} nodeId - Hardware id
   * @property {string} algorithm - Hardware algorithm
   * @property {string} manufacturer - Hardware manufacturer
   * @property {string} model - Hardware model
   * @property {string} oui - Hardware oui
   * @property {string} type - Hardware type
   */
  constructor(data = {}) {
    this.algorithm = data.algorithm || '';
    this.manufacturer = data.manufacturer || '';
    this.model = data.model || '';
    this.name = data.name || '';
    this.oui = data.oui || '';
    this.type = data.type || '';
    this.id = data.id || '';
  }
}
