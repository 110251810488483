/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import UserApiService from '@/services/api/users.api-service';
import UserModel from '@/model/user/user.model';
import store from '@/store';

export default {
  state: {
    userData: new UserModel(),
    users: [],
    count: 0,
    delete: false,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.userData = new UserModel(userData);
    },
    SET_USERS(state, users) {
      state.users = users.data.map((user) => new UserModel(user));
      state.count = users.count;
    },
    REMOVE_USER(state, id) {
      state.users.splice(state.users.findIndex((user) => user.id === id), 1);
    },
  },
  getters: {
    getUserData: (state) => state.userData,
    list: (state) => state.users,
    count: (state) => state.count,
  },
  actions: {
    reset_user({ commit }) {
      commit('SET_USER_DATA', {});
    },
    async get_user({ commit }, id) {
      commit('SET_USER_DATA', await UserApiService.getUser(id));
    },
    async fetch({ state, commit }, parameters = {}) {
      if (state.delete && state.users.length === 0 && parameters.page > 1) {
        parameters.page -= 1;
      }
      const params = {
        limit: parameters.itemsPerPage || 10,
        page: parameters.page || 1,
        searchText: parameters.search || '',
        orderBy: parameters.sortBy && parameters.sortBy.length ? parameters.sortBy[0] : 'created',
        order: parameters.sortDesc && parameters.sortDesc[0] ? 'DESC' : 'ASC',
        filterBy: parameters.filterBy && parameters.filterBy !== 'All' ? { type: parameters.filterBy } : {},
      };

      commit('SET_USERS', await UserApiService.fetchUsers({ params }));
    },
    async remove({ state, commit }, { id }) {
      state.delete = true;
      await UserApiService.remove(id);
      commit('REMOVE_USER', id);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'users.list.deletedItem',
        color: 'success',
        showClose: true,
      });
    },
    async create_or_update_user({ commit }, allData) {
      const formData = new FormData();
      formData.append('data', JSON.stringify(allData.data));
      if (allData.avatar) {
        formData.append('avatar', allData.avatar);
      }
      commit('SET_USER_DATA', await UserApiService.createOrUpdateUser(formData, allData.data.id));
    },
    // eslint-disable-next-line no-unused-vars
    update_local_storage({ commit }, userData) {
      let userDetails = JSON.parse(localStorage.getItem('userDetails'));
      userDetails = { ...userDetails, ...userData };
      localStorage.setItem('userDetails', JSON.stringify(userDetails));
      store.dispatch('auth/update_user', JSON.parse(localStorage.getItem('userDetails')));
    },
  },
};
